import { configureStore } from "@reduxjs/toolkit";
import Auth from "./reducers/authReducer";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: Auth,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
