import React, { FormEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import styles from "../assets/modules/auth.module.css";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleCompanyCode,
  handleUserLogin,
  checkCompany,
} from "../redux/reducers/authReducer";
import { AppDispatch } from "../redux/store";
import SnackBar from "../components/SnackBar";
import AppLoading from "./AppLoading";
const StyledCompany = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px var(--secondary-color);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 300px;
`;
const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;
const StyledImage = styled.img`
  width: 60px;
  height: 60px;
`;

export interface ISnackBar {
  open: boolean;
  message?: string;
}
export default function Auth() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("auth");
  const [step, setStep] = useState(1);
  const [coCode, setCoCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<ISnackBar>({ open: false });
  const [error, setError] = useState(false);

  const { companyData } = useSelector((state: any) => ({
    companyData: state.companyData,
  }));

  console.log(companyData);

  const companyCodeSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (coCode.length === 0) {
      setError(true);
    } else {
      const companyCode = coCode;
      dispatch(
        handleCompanyCode({
          code: companyCode,
          setLoading,
          setStep,
          setSnackBar,
        })
      );
    }
  };

  const loginSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (username.length === 0 || password.length === 0) {
      setError(true);
    } else {
      const data = { username, password };
      dispatch(handleUserLogin({ data: data, setLoading, setSnackBar }));
    }
  };
  const { isLoggedIn } = useSelector((state: any) => ({
    isLoggedIn: state.isLoggedIn,
  }));
  useEffect(() => {
    dispatch(checkCompany({ setStep }));
  }, [dispatch]);

  const handleStepBack = () => {
    setStep(1);
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className={styles.authPage}>
        <>
          {loading ? <AppLoading /> : null}
          {snackBar.open ? <SnackBar snackBar={snackBar} /> : null}
          {step === 1 ? (
            <div className={styles.authContainer}>
              <div className={styles.authCard}>
                <div className={styles.companyCode}>
                  {t("enter_company_code")}
                </div>
                <StyledCompany>
                  <form
                    className={styles.authForm}
                    onSubmit={companyCodeSubmit}
                  >
                    <input
                      className={styles.authInput}
                      placeholder={`${t("company_code")}`}
                      value={coCode}
                      onChange={(e) => {
                        setCoCode(e.target.value);
                        setError(false);
                      }}
                    />
                    {error && coCode.length === 0 ? (
                      <p className={styles.error}>
                        {coCode.length === 0 && t("company_code_req")}
                      </p>
                    ) : (
                      ""
                    )}
                    <button className={styles.authBtn}>{t("enter")}</button>
                  </form>
                </StyledCompany>
              </div>
            </div>
          ) : (
            <div className={styles.authContainer}>
              <div className={styles.authCard}>
                <div className={styles.companyCode}>{t("login")}</div>
                <div className={styles.companyData}>
                  <div>{companyData.CompanyName}</div>
                  <div className={styles.companyLogo}>
                    <StyledImage
                      src={
                        companyData.Logo ||
                        `https://ahmedaglan.github.io/ClientsJSON/ClientImages/${companyData.ClientID}/logo.png`
                      }
                    />
                  </div>
                </div>
                <form onSubmit={loginSubmit}>
                  <StyledUser>
                    <input
                      className={styles.authInput}
                      type="text"
                      placeholder={`${t("user_name")}`}
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setError(false);
                      }}
                    />
                    {error && username.length === 0 ? (
                      <p className={styles.error}>{t("username_error")}</p>
                    ) : (
                      ""
                    )}
                    <input
                      className={styles.authInput}
                      type="password"
                      placeholder={`${t("user_password")}`}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError(false);
                      }}
                    />
                    {error && password.length === 0 ? (
                      <p className={styles.error}>{t("Password_Error")}</p>
                    ) : (
                      ""
                    )}
                    <button type="submit" className={styles.authBtn}>{`${t(
                      "enter"
                    )}`}</button>
                    <div className={styles.ChangeCompanyCode}>
                      <button
                        onClick={handleStepBack}
                        className={styles.authBtn}
                      >
                        {t("change_code")}
                      </button>
                    </div>
                  </StyledUser>
                </form>
              </div>
            </div>
          )}
        </>

        <div className={styles.oceanContainer}>
          <div className={styles.ocean}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
          </div>
        </div>
      </div>
    );
  }
}
