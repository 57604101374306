import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../assets/modules/shipmentDetail.module.css";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import NotFound from "./NotFound";
const Card = styled.div`
  padding: 1.5rem 1rem;
  box-shadow: 0px 3px 6px #00000029;
`;
const CardTitle = styled.h3`
  color: var(--primary-color);
  font-weight: 900;
  margin-bottom: 12px;
`;
export default function ShipmentDetails() {
  const { awb } = useParams();
  const [details, setDetails] = useState<any>();

  const AuthedUser = JSON.parse(localStorage.getItem("AuthedUser") || "");
  const CompanyData = JSON.parse(localStorage.getItem("CompanyData") || "");

  useEffect(() => {
    const response = axios
      .get(
        `https://vsoft.com-eg.net:9091/api/Shipments/V1/GetShipmentDetails/${awb}`,
        {
          headers: {
            awb: awb,
            companyid: CompanyData.ClientID,
            accesstoken: AuthedUser.data.AccessToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setDetails(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, [awb]);

  const { t } = useTranslation("shipmentDetail");
  console.log(details);
  // console.log(details.shipmentInfo.length);
  return (
    <>
      {details == undefined || details.shipmentInfo.length === 1 ? (
        <div className={styles.container}>
          <div className={styles.heading}>
            <div className={styles.title}>{t("shipment_header")}</div>
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.shipmentData}>
              <Card>
                <CardTitle>{t("shipment_info.title")}</CardTitle>{" "}
                <div className={styles.cardEntries}>
                  <div className={styles.cardEntry}>
                    {t("shipment_info.shipment_number")} :
                    <div className={styles.entryValue}>
                      {details?.shipmentInfo[0]?.AWB}
                    </div>
                  </div>
                  {details?.shipmentInfo[0].RunnerName && (
                    <div className={styles.cardEntry}>
                      {t("shipment_info.courier")} :
                      <div className={styles.entryValue}>
                        {`${details?.shipmentInfo[0].RunnerMobile} ${
                          "/" + details?.shipmentInfo[0].RunnerName
                        } `}
                      </div>
                    </div>
                  )}

                  <div className={styles.cardEntry}>
                    {t("shipment_info.status")} :
                    <div className={styles.entryValue}>
                      {details?.shipmentInfo[0].StatusName}
                    </div>
                  </div>
                  <div className={styles.cardEntry}>
                    {t("shipment_info.delivery_date")} :
                    <div className={styles.entryValue}>
                      {details?.shipmentInfo[0].PickupDate.slice(0, 10)}
                    </div>
                  </div>
                  <div className={styles.cardEntry}>
                    {t("shipment_info.content")} :
                    <div className={styles.entryValue}>
                      {details?.shipmentInfo[0].Contents}
                    </div>
                  </div>
                  {details?.shipmentInfo[0].Reason && (
                    <div className={styles.cardEntry}>
                      {t("shipment_info.reason")} :
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].Reason}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
              <Card>
                <CardTitle>{t("sender_consignee_info.title")}</CardTitle>
                <div className={styles.entriesContainer}>
                  <div className={styles.cardEntries}>
                    {details?.shipmentInfo[0].SenderName && (
                      <div className={styles.cardEntry}>
                        {t("sender_consignee_info.main_sender")} :
                        <div className={styles.entryValue}></div>
                      </div>
                    )}

                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.sender_phone")} :
                      <div className={styles.entryValue}></div>
                    </div>
                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.sub_sender")} :
                      <div className={styles.entryValue}></div>
                    </div>
                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.COD")} :
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].COD}
                      </div>
                    </div>
                  </div>
                  <div className={styles.seperator}></div>
                  <div className={styles.cardEntries}>
                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.consignee")} :
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].ConsigneeName}
                      </div>
                    </div>
                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.consignee_number")} :
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].Phone1}
                      </div>
                    </div>
                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.city")} :
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].ToCity}
                      </div>
                    </div>

                    <div className={styles.cardEntry}>
                      {t("sender_consignee_info.address")}
                      <div className={styles.entryValue}>
                        {details?.shipmentInfo[0].Address}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <Card className={styles.shipmentHistory}>
              <CardTitle>{t("shipment_history.title")}</CardTitle>
              <div className={styles.trackingContainer}>
                {details?.tracking.reverse().map((status: any) => (
                  <div key={status.AuditDate}>
                    <div className={styles.track}>
                      <div className={styles.statusIntro}>
                        {status.StatusName !== "General Update" && (
                          <>
                            <div className={styles.statusName}>
                              {status.StatusName}
                            </div>
                            <h5>
                              {status.AuditDate.slice(
                                12,
                                status.AuditDate.length
                              )}{" "}
                              {status.AuditDate.slice(0, 10)}
                            </h5>
                          </>
                        )}
                      </div>
                      {status.StatusName === "General Update" ? (
                        <>
                          <div className={styles.reason}>s</div>
                        </>
                      ) : (
                        <>
                          {status.Reason && (
                            <div className={styles.reason}>{status.Reason}</div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
}
