import React from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";

import styled from "styled-components";

export const Button = styled.div`
  border: 1px solid #eee;
  padding: 0.2rem;
  width: fit-content;
  display: flex;
  place-content: center;
  user-select: none;
`;
export const DateRangeWrapper = styled.div`
  @media (max-width: 768px) {
    & > .rdrDateRangePickerWrapper {
      display: flex;
      flex-direction: column;
    }
  }
`;
interface Value {
  handleChange(e: any): void;
  value: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

const DateSelector = ({ handleChange, value }: Value) => {
  const handleDate = (d: Date) => {
    const date = new Date(d);
    const day = date.getDate();
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    return `${day} - ${month} - ${year}`;
  };
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button onClick={() => setOpen((old) => !old)}>{`${handleDate(
        value.startDate
      )} / ${handleDate(value.endDate)}  `}</Button>
      {open && (
        <DateRangeWrapper>
          <DateRangePicker ranges={[value]} onChange={handleChange} />
        </DateRangeWrapper>
      )}
    </div>
  );
};
export default DateSelector;
