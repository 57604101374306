import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import {
  Home,
  Clients,
  Branches,
  Auth,
  Finance,
  Returns,
  Runners,
  Performance,
  Predictions,
  Subscription,
  ClientsAnalysis,
  ReturnAnalysis,
  RunnerAnalysis,
  NotFound,
} from "./pages";
import ShipmentDetails from "./pages/ShipmentDetails";
import ExternalPartners from "./pages/ExternalPartners";
import Main from "./Main";
import ChangePassword from "./pages/ChangePassword";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./redux/store";
import { checkUser } from "./redux/reducers/authReducer";

export default function AppRoutes() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(checkUser());
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/branches" element={<Branches />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients_analysis" element={<ClientsAnalysis />} />
          <Route path="/runners" element={<Runners />} />
          <Route path="/runners_analysis" element={<RunnerAnalysis />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/returns" element={<Returns />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/predictions" element={<Predictions />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/returns_analysis" element={<ReturnAnalysis />} /> */}
          <Route path="/shipment/:awb" element={<ShipmentDetails />} />
          <Route path="/external-partners" element={<ExternalPartners />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </>
  );
}
