import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import styles from "../assets/modules/changePassword.module.css";
import axios from "axios";
export default function ChangePassword() {
  const navigate = useNavigate();
  const [passErr, setPassErr] = useState("");
  const { t } = useTranslation("auth");
  const schema = yup
    .object({
      password: yup.string().required(`${t("Password_Error")}`),
      newPassword1: yup.string().required(`${t("Password_Error")}`),
      newPassword2: yup
        .string()
        .required(`${t("Password_Error")}`)
        .oneOf([yup.ref("newPassword1")], `${t("Password_match")}`),
    })
    .required();
  const methods: any = useForm({
    mode: "onTouched",
    defaultValues: {
      password: "",
      newPassword1: "",
      newPassword2: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const { authedUser } = useSelector((state: any) => ({
    authedUser: state.AuthedUser.Username,
  }));

  async function SubmitForm(data: any) {
    const AuthedUser = JSON.parse(localStorage.getItem("AuthedUser") || "");
    const CompanyData = JSON.parse(localStorage.getItem("CompanyData") || "");
    const res = await axios
      .post(
        `https://vsoft.com-eg.net:9091/api/AdminUsers/V1/ChangePassword`,
        {
          userName: authedUser,
          password: data.password,
          newPassword1: data.newPassword1,
          newPassword2: data.newPassword2,
        },
        {
          headers: {
            companyid: CompanyData.ClientID,
            accesstoken: AuthedUser.AccessToken,
          },
        }
      )
      .then((res) => {
        navigate("/");
      })
      .catch((res) => {
        setPassErr(`${t("invalid_password")}`);
      });
  }
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(SubmitForm)}>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              width: "100%",
              height: "85vh",
            }}
          >
            <div className={styles.passContainer}>
              <h2>{t("change_password")}</h2>
              <div className={styles.passInput}>
                <label className={styles.passowrdLabel} htmlFor="password">
                  {t("old_password")}
                </label>
                <input
                  className={styles.passwordInput}
                  id="password"
                  type="password"
                  {...register("password")}
                  placeholder={`${t("Password_Placeholder")}`}
                />
                {(errors.password && (
                  <div style={{ color: "red" }}>{errors.password.message}</div>
                )) ||
                  (passErr && <div style={{ color: "red" }}>{passErr}</div>)}
              </div>
              <div className={styles.passInput}>
                <label className={styles.passowrdLabel} htmlFor="newPassword1">
                  {t("new_password")}
                </label>
                <input
                  className={styles.passwordInput}
                  id="newPassword1"
                  type="password"
                  {...register("newPassword1")}
                  placeholder={`${t("Password_Placeholder")}`}
                />
                {errors.newPassword1 && (
                  <div style={{ color: "red" }}>
                    {errors.newPassword1.message}
                  </div>
                )}
              </div>
              <div className={styles.passInput}>
                <label className={styles.passowrdLabel} htmlFor="newPassword2">
                  {t("confirm_password")}
                </label>
                <input
                  className={styles.passwordInput}
                  id="newPassword2"
                  type="password"
                  {...register("newPassword2")}
                  placeholder={`${t("Password_Placeholder")}`}
                />
                {errors.newPassword2 && (
                  <div style={{ color: "red" }}>
                    {errors.newPassword2.message}
                  </div>
                )}
              </div>
              <div
                className={styles.submitBtnContainer}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <button className={styles.submitBtn} type="submit">
                  {t("change_password")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
