import React from "react";

export default function HomeIcon() {
  return (
    <svg
      id="noun_Home_1191731"
      xmlns="http://www.w3.org/2000/svg"
      width="33.733"
      height="32.832"
      viewBox="0 0 33.733 32.832"
    >
      <path
        id="Path_110"
        data-name="Path 110"
        d="M39.6,32.737a1.472,1.472,0,0,0-1.472,1.472V43.4a2.149,2.149,0,0,1-2.142,2.15H24.094V37.007a1.752,1.752,0,0,1,1.752-1.752H28.01a1.752,1.752,0,0,1,1.752,1.752v4.417a1.472,1.472,0,1,0,2.945,0V37.007a4.7,4.7,0,0,0-4.7-4.7H25.846a4.7,4.7,0,0,0-4.7,4.7v8.546H17.874a2.149,2.149,0,0,1-2.149-2.15V34.209a1.472,1.472,0,1,0-2.945,0V43.4A5.1,5.1,0,0,0,17.874,48.5H35.983A5.1,5.1,0,0,0,41.077,43.4V34.209a1.472,1.472,0,0,0-1.48-1.472Z"
        transform="translate(-10.13 -15.666)"
        fill="currentColor"
      />
      <path
        id="Path_111"
        data-name="Path 111"
        d="M42.475,24.062,29.659,11.209a5.153,5.153,0,0,0-7.288,0L9.482,24.062a1.472,1.472,0,0,0,2.076,2.054l12.86-12.823a2.208,2.208,0,0,1,3.129,0L40.4,26.116a1.473,1.473,0,0,0,2.083-2.083Z"
        transform="translate(-9.18 -9.699)"
        fill="currentColor"
      />
    </svg>
  );
}
