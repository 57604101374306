import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import styles from "./assets/modules/layout.module.css";
import NavigationBar from "./components/layout/NavigationBar";
import SideBar from "./components/layout/SideBar";
export default function Main() {
  const { isLoggedIn } = useSelector((state: any) => ({
    isLoggedIn: state.isLoggedIn,
  }));

  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setIsMobile(window.innerWidth <= 768);
    });

    return window.removeEventListener("resize", (e) => {
      console.log(e);
    });
  }, []);
  if (isLoggedIn === false) return <Navigate to="/auth" />;
  return (
    <>
      <NavigationBar isMobile={isMobile} toggle={toggle} />
      <div className={styles.layoutContainer}>
        {!isMobile && <SideBar open={open} />}
        <div
          className={`${isMobile ? styles.contentSidevw : styles.contentSide}`}
        >
          <div className={styles.payload}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
