import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "../modules/navbar.module.css";
import { handleLogout } from "../../redux/reducers/authReducer";

const Logout = () => {
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch<any>();

  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let handler = (e: any) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const handleLogoutClick = () => {
    dispatch(handleLogout());
  };
  return (
    <div className={styles.logoutContainer}>
      <svg
        onClick={() => setOpen(!open)}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="38"
        height="38"
        x="0"
        y="0"
        viewBox="0 0 24 24"
      >
        <g>
          <g fillRule="evenodd">
            <path
              d="M11.112.815a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5zM19.652 12.09c-.486-.55-1.2-.9-1.993-.9H15.75a2.658 2.658 0 0 0-2.658 2.659v6.678a2.658 2.658 0 0 0 2.658 2.658h1.908c.794 0 1.507-.35 1.993-.9a.75.75 0 0 0-1.124-.992 1.153 1.153 0 0 1-.87.392h-1.907c-.64 0-1.158-.519-1.158-1.158v-6.678c0-.64.518-1.159 1.158-1.159h1.908c.346 0 .655.151.869.393a.75.75 0 0 0 1.124-.993z"
              fill={open ? "var(--primary-color)" : "#000"}
              data-original="#000000"
            ></path>
            <path
              d="M22.497 16.657a.75.75 0 0 1 0 1.06l-2 2a.75.75 0 0 1-1.061-1.06l2-2a.75.75 0 0 1 1.06 0z"
              fill={open ? "var(--primary-color)" : "#000"}
              data-original="#000000"
            ></path>
            <path
              d="M22.497 17.718a.75.75 0 0 0 0-1.06l-2-2a.75.75 0 0 0-1.061 1.06l2 2a.75.75 0 0 0 1.06 0z"
              fill={open ? "var(--primary-color)" : "#000"}
              data-original="#000000"
            ></path>
            <path
              d="M15.716 17.188a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75z"
              fill={open ? "var(--primary-color)" : "#000"}
              data-original="#000000"
            ></path>
          </g>
          <path
            d="M11.608 13.49c-.01.119-.015.238-.015.359v6.678c0 .746.196 1.445.54 2.05h-9.11a1.75 1.75 0 0 1-1.75-1.75v-3.671c0-.613.338-1.176.88-1.464a18.895 18.895 0 0 1 9.455-2.201z"
            fill={open ? "var(--primary-color)" : "#000"}
            data-original="#000000"
          ></path>
        </g>
      </svg>
      <span
        ref={dropdownRef}
        className={styles.logoutList}
        style={open === true ? { display: "block" } : { display: "none" }}
      >
        <ul
          style={{
            listStyle: "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <li
            onClick={() => {
              navigate("/change-password");
              setOpen(false);
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "5px",
              }}
            >
              <div>{t(`sidebar.changePass`)}</div>
            </div>
          </li>
          <li
            onClick={() => {
              setOpen(false);
              handleLogoutClick();
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "5px",
              }}
            >
              <div>{t(`sidebar.logout`)}</div>
            </div>
          </li>
        </ul>
      </span>
    </div>
  );
};

export default Logout;
