import React from "react";
import { MenuItem } from "../utilities";
import styles from "../../assets/modules/navbar.module.css";

import {
  BranchesIcon,
  ClientsIcon,
  FinanceIcon,
  HomeIcon,
  ReturnsIcon,
  RunnersIcon,
  PerformanceIcon,
  PredictionIcon,
  SubscriptionIcon,
} from "../../assets/icons";
import Searchbar from "./Searchbar";
import ExternalIcon from "../../assets/icons/ExternalIcon";
interface Iscollapse {
  collapsed: boolean;
}
export default function AdaptiveNavBar({ collapsed }: Iscollapse) {
  if (collapsed) {
    return (
      <div>
        <div>
          <div className={styles.AdaptiveNavBar}>
            <Searchbar />
            <MenuItem text="home">
              <HomeIcon />
            </MenuItem>
            {/* <MenuItem text="branches">
              <BranchesIcon />
            </MenuItem>
            <MenuItem text="clients">
              <ClientsIcon />
            </MenuItem>
            <MenuItem text="returns">
              <ReturnsIcon />
            </MenuItem>
            <MenuItem text="runners">
              <RunnersIcon />
            </MenuItem>
            <MenuItem text="finance">
              <FinanceIcon />
            </MenuItem>
            <MenuItem text="performance">
              <PerformanceIcon />
            </MenuItem>
            <MenuItem text="predictions">
              <PredictionIcon />
            </MenuItem>
            <MenuItem text="subscription">
              <SubscriptionIcon />
            </MenuItem> */}
            <MenuItem text="external-partners">
              <ExternalIcon />
            </MenuItem>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
