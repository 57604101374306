import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
interface IInitial  {
  isLoggedIn:any;
  loading:boolean;
  companyData:any;
  AuthedUser:any;
  
}
const initialState:IInitial = {
  isLoggedIn: null,
  AuthedUser: null,
  loading: false,
  companyData: false,

};

// ============================= Tool Kit ================================
export const handleCompanyCode = createAsyncThunk(
  "Auth/handleCompanyCode",
  async ({ code, setLoading, setStep, setSnackBar }: any) => {
    setLoading(true);
    const response = await axios.get(
      "https://ahmedaglan.github.io/ClientsJSON/ClientsMPS.json"
    );
    const data = response.data.ClientsList;
    const founded = await data.find(
      (client: any) => client.ClientID.toString() === code.trim()
    );
    // send data by action.payload and trim it as founded in fulfilled processing
    if (founded) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      setTimeout(() => {
        setStep(2);
      }, 500);
      return founded;
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      setTimeout(() => {
        setSnackBar({ open: true, message: "invalid company code" });
      }, 500);
      setTimeout(() => {
        setSnackBar({ open: false, message: false });
      }, 4000);
    }
  }
);
// =======================================================================
export const checkCompanyData = createAsyncThunk(
  "Auth/checkCompanyData",
  async () => {
    let res = await axios.get("/meta/metadata.json");
    return res.data;
  }
);
// =============================================================================
export const handleUserLogin = createAsyncThunk(
  "auth/handleUserLogin",
  async ({ data, setLoading, setSnackBar }: any) => {
    const { username, password } = data;
    try {
      setLoading(true);
      const response = await axios.post(
        "https://vsoft.com-eg.net:9091/api/AdminUsers/V1/Login",
        {
          username,
          password,
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 500);
      console.log(response);
      return response;
    } catch (err: any) {
      setLoading(false);
      setSnackBar({ open: true, message: err.response.data.message });
      setTimeout(() => {
        setSnackBar({ open: false, message: false });
      }, 4000);
    }
  }
);
// ==========================================================================
export const handleLogout = createAsyncThunk("Auth/handleLogout", async () => {
  const AuthedUser = JSON.parse(localStorage.getItem("AuthedUser") || "");
  const CompanyData = JSON.parse(localStorage.getItem("CompanyData") || "");

  const response = await axios.post(
    "https://vsoft.com-eg.net:9091/api/AdminUsers/V1/Logout",
    {},
    {
      headers: {
        companyid: CompanyData.ClientID,
        accesstoken: AuthedUser.AccessToken,
      },
    }
  );
  return response;
});
// ============================================================================
export const handleShipmentDetails = createAsyncThunk(
  "handleShipmentDetails",
  async () => {
    const AuthedUser = JSON.parse(localStorage.getItem("AuthedUser") || "");
    const CompanyData = JSON.parse(localStorage.getItem("CompanyData") || "");

    const response = await axios.post(
      "https://vsoft.com-eg.net:9091/api/Shipments/V1/GetShipmentDetails/ADD000902",
      {},
      {
        headers: {
          companyid: CompanyData.ClientID,
          accesstoken: AuthedUser.AccessToken,
        },
      }
    );
    return response;
  }
);
// ============================================================================
const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    //actions
    checkUser: (state) => {
      const AuthedUser = localStorage.getItem("AuthedUser");
      if (AuthedUser) {
        const authedUserData = JSON.parse(AuthedUser);
        state.AuthedUser = authedUserData;
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    logOut: (state) => {
      state.AuthedUser = null;
      state.isLoggedIn = false;
      console.log("out");
    },
    checkCompany: (state, action) => {
      const companyData = localStorage.getItem("CompanyData");
      if (companyData) {
        state.companyData = JSON.parse(companyData);
        action.payload.setStep(2);
      } else {
        action.payload.setStep(1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleUserLogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(handleUserLogin.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.AuthedUser = action.payload.data;
      localStorage.setItem("AuthedUser", JSON.stringify(action.payload.data));
      state.isLoggedIn = true;
    });
    builder.addCase(handleUserLogin.rejected, (state: any) => {
      state.loading = false;
    });
    builder.addCase(handleCompanyCode.pending, (state: any) => {
      state.loading = true;
    });

    builder.addCase(handleCompanyCode.fulfilled, (state: any, action: any) => {
      if (typeof action.payload == "undefined") {
        state.companyData = false;
      } else {
        state.loading = false;
        state.companyData = action.payload;
        localStorage.setItem("CompanyData", JSON.stringify(action.payload));
      }
    });
    builder.addCase(handleCompanyCode.rejected, (state: any) => {
      state.loading = false;
    });
    builder.addCase(handleLogout.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(handleLogout.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.AuthedUser = action.payload;
      localStorage.removeItem("AuthedUser");
      state.isLoggedIn = false;
    });
    builder.addCase(handleLogout.rejected, (state: any) => {
      state.loading = false;
      localStorage.removeItem("AuthedUser");
      state.isLoggedIn = false;
    });
    builder.addCase(checkCompanyData.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(checkCompanyData.fulfilled, (state: any, action: any) => {
      if (typeof action.payload === "string") {
        const companyData = localStorage.getItem("CompanyData");
        if (companyData) {
          let companyMetaData = JSON.parse(companyData);
          delete companyMetaData.defined;
          state.companyData = companyMetaData;
        } else {
          if (action.payload.setStep) {
            action.payload.setStep(1);
          }
        }
      } else {
        const metaData = { ...action.payload, defined: true };
        localStorage.removeItem("CompanyData");
        localStorage.setItem("CompanyData", JSON.stringify(metaData));
        state.companyData = metaData;
      }
    });
    builder.addCase(checkCompanyData.rejected, (state: any, action: any) => {
      const companyData = localStorage.getItem("CompanyData");
      if (companyData) {
        const companyMetaData = JSON.parse(companyData);
        delete companyMetaData.defined;
        state.companyData = companyMetaData;
      }
    });
  },
});

export const { checkCompany, checkUser, logOut } = authSlice.actions;
export default authSlice.reducer;
