import React from "react";

type IProps ={
  handleCollapse:()=>void  
} 

export default function MobileMenuIcon({ handleCollapse }: IProps) {
  return (
    <svg
      onClick={() => {
        handleCollapse();
      }}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      width="48"
      cursor="pointer"
    >
      <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
    </svg>
  );
}
