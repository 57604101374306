import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/modules/external.module.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import AppLoading from "./AppLoading";
import NotFound from "./NotFound";
export default function ExternalPartners() {
  const { t } = useTranslation("external");
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let handler = (e: any) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const {
    isLoading,
    isError,
    data: externalData,
  } = useQuery(
    "external",
    async () => {
      const { data } = await axios.post(
        `https://vsoft.com-eg.net:9091/api/ExternalParterner/V1/GetDRSStatus`,
        {
          pageSize: 1000,
          pageNumber: 0,
        },
        {
          headers: {
            CompanyID: "999999",
            AccessToken: "7FB544B7-1053-42C7-A20C-A1CA9BACFC44",
            "Accept-Encoding": "gzip, deflate, br",
          },
        }
      );

      return data;
    },
    { refetchOnWindowFocus: false }
  );
  if (isLoading) {
    return <AppLoading />;
  }
  if (isError || !externalData?.data.length) {
    return <NotFound />;
  }
  return (
    <>
      <div
        style={open === true ? { filter: "blur(4px)" } : { filter: "blur(0)" }}
      >
        <p className={styles.externalPartners}>{t("external_partners")}</p>
        <table className={styles.blueTable}>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Partner</th>
              <th>Distribution Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {externalData?.data?.map((row: any) => (
              <tr key={row.TransID}>
                {row.status === "failed" ? (
                  <td className={styles.idLink} onClick={() => setOpen(!open)}>
                    {row.TransID}
                  </td>
                ) : (
                  <td>{row.TransID}</td>
                )}

                <td>{row.RunnerName}</td>
                <td>{row?.TransDate.replace("T", " ").split(".")[0]}</td>
                <td
                  className={
                    row.Status === "Success"
                      ? styles.statusSuccess
                      : row.Status === "Pending"
                      ? styles.statusPending
                      : styles.statusFailed
                  }
                >
                  {row.Status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {open && (
        <>
          <div
            style={open === true ? { display: "block" } : { display: "none" }}
            className={styles.modal}
          >
            <div className={styles.modalContent}>
              <div>Attention Please !!</div>
              <div>
                <button onClick={() => setOpen(!open)}>Close</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
