import React, { useState, useEffect } from "react";
import styles from "../../assets/modules/navbar.module.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledNavLink } from "../utilities";
import {
  SubscriptionIcon,
  PerformanceIcon,
  PredictionIcon,
  MenuIcon,
} from "../../assets/icons";
import MobileMenu from "../../assets/icons/MobileMenuIcon";
import AdaptiveNavBar from "./AdaptiveNavBar";
import Translation from "../Translation";
import Logout from "../../assets/icons/Logout";
import Searchbar from "./Searchbar";
type INav = {
  isMobile: boolean;
  toggle: React.MouseEventHandler<HTMLDivElement>;
};
export default function NavigationBar({ isMobile, toggle }: INav) {
  const [collapse, setCollapse] = useState<boolean>(false);

  let location = useLocation().pathname.replace("/", "");
  const { t } = useTranslation("layout");
  const handleCollapse = () => {
    setCollapse((old) => !old);
  };
  if (location.length === 0) {
    location = "home";
  }
  t(`sidebar`);
  useEffect(() => {
    setCollapse(false);
  }, [location]);
  const pathes = [
    "branches",
    "home",
    "clients",
    "clients_analysis",
    "runners",
    "runners_analysis",
    "finance",
    "returns",
    "performance",
    "predictions",
    "subscription",
    "returns_analysis",
    "external-partners",
  ];
  const foundedPath = pathes.find((el) => {
    return el === location;
  });

  return (
    <div>
      <div className={styles.navbarContainer}>
        <div className={styles.navbar}>
          <div className={styles.brand}>
            <div onClick={toggle} className={styles.menuIcon}>
              <MenuIcon />
            </div>
            {isMobile && <MobileMenu handleCollapse={handleCollapse} />}
            <div className={styles.companyInfo}>
              <div className={styles.companyLogo}>V Soft</div>
              <div className={styles.companyName}>V-Soft</div>
            </div>
            <h1 className={styles.pageTitle}>
              {foundedPath ? t(`sidebar.${location}`) : " "}
            </h1>
          </div>
          {isMobile ? (
            <div className={styles.navbarMobile}>
              <div>
                {/* <StyledNavLink to="/"> */}
                <Translation />
                {/* </StyledNavLink> */}
              </div>
              <div>
                <Logout />
              </div>
            </div>
          ) : (
            <>
              <div>
                <Searchbar />
              </div>
              <div className={styles.navbarPages}>
                <div>
                  <Logout />
                </div>

                {/* <StyledNavLink to="/"> */}
                <div>
                  <Translation />
                </div>
                <div style={{ width: "30px" }}></div>
                {/* remove this div if you uncomment any styled navlink in bottom */}
                {/* </StyledNavLink> */}
                {/* <StyledNavLink to="/predictions">
                  <PredictionIcon />
                </StyledNavLink>
                <StyledNavLink to="/performance">
                  <PerformanceIcon />
                </StyledNavLink>
                <StyledNavLink to="/subscription">
                  <SubscriptionIcon />
                </StyledNavLink> */}
              </div>
            </>
          )}
        </div>
        {isMobile && <AdaptiveNavBar collapsed={collapse} />}
      </div>
    </div>
  );
}
