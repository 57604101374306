import React from "react";
import styles from "../../assets/modules/sidebar.module.css";
import { MenuItem } from "../utilities";
import {
  BranchesIcon,
  ClientsIcon,
  FinanceIcon,
  HomeIcon,
  ReturnsIcon,
  RunnersIcon,
} from "../../assets/icons/index";
import ExternalIcon from "../../assets/icons/ExternalIcon";
interface Open {
  open: boolean;
}
export default function SideBar({ open }: Open) {
  return (
    <div
      className={styles.sidebarContainer}
      style={{
        width: open ? "350px" : "90px",
      }}
    >
      <div className={styles.AppList}>
        <MenuItem text="home">
          <HomeIcon />
        </MenuItem>
        {/* <MenuItem text="branches">
          <BranchesIcon />
        </MenuItem>
        <MenuItem text="clients">
          <ClientsIcon />
        </MenuItem>
        <MenuItem text="returns">
          <ReturnsIcon />
        </MenuItem>
        <MenuItem text="runners">
          <RunnersIcon />
        </MenuItem>
        <MenuItem text="finance">
          <FinanceIcon />
        </MenuItem> */}
        <MenuItem text="external-partners">
          <ExternalIcon />
        </MenuItem>
      </div>
    </div>
  );
}
