import styled from "styled-components";
import React, {
  useState,
  useEffect,
  useRef,
  MouseEventHandler,
  ReactNode,
} from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../assets/modules/progress.module.css";

interface IMenuItem {
  text: any;
  children: ReactNode;
}
interface IArrow {
  handleClick?(): MouseEventHandler;
}
interface IProps {
  progress: number;
  circleTwoStroke: string;
  size: number;
}
interface IColor {
  color: string;
}
const StyledMenuItem = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
  height: 60px;
`;
export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  &.active {
    color: var(--primary-color);
  }
  & svg {
    transform: scale(0.8);
  }
  text-decoration: none;
  color: black;
  width: 100%;
`;
const StyledIcon = styled.div`
  width: 40px;
  display: grid;
  place-items: center;
`;
export const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 10px;
  min-height: 250px;
  cursor: grab !important;
  transition: all 1s;
  scroll-behavior: smooth;
`;
// ================================================
export const MenuItem = ({ text, children }: IMenuItem) => {
  const { t } = useTranslation("layout");
  let link = text === "home" ? "/" : text;

  return (
    <StyledNavLink to={`${link}`}>
      <StyledMenuItem>
        <StyledIcon>{children}</StyledIcon>
        <div>{text ? t(`sidebar.${text}`) : ""}</div>
      </StyledMenuItem>
    </StyledNavLink>
  );
};
// ==========================================
export const ProgressBar = (props: IProps) => {
  const [offset, setOffset] = useState(0);

  const circleRef = useRef<any>();
  const { progress, circleTwoStroke, size } = props;
  const center = size / 2;
  const radius = size / 2 - 15 / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    circleRef.current.style = "transition: all 0.8s ease-in-out";
  }, [setOffset, progress, circumference, offset]);

  return (
    <div>
      <svg className={styles.svg} width={size} height={size}>
        <circle
          className={styles.svg_circle_bg}
          stroke={"#DCEBF5"}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={6}
        />
        <circle
          className={styles.svg_circle}
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={6}
          strokeDasharray={circumference}
          strokeDashoffset={-offset}
        />
        <text
          x={`${center}`}
          y={`${center}`}
          className={styles.svg_circle_text}
          dominantBaseline="middle"
          style={{ fill: `${circleTwoStroke}` }}
        >
          {progress}%
        </text>
      </svg>
    </div>
  );
};
//  =========================================
export const ArrowSvg = () => {
  return (
    <div>
      <svg
        version="1.1"
        width="12"
        height="12"
        x="0"
        y="0"
        viewBox="0 0 123.959 123.958"
      >
        <g>
          <g>
            <path
              d="M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56
		C125.979,34.417,123.279,28.017,117.979,28.017z"
              fill="var(--primary-color)"
              data-original="#000000"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

// ==============================================

export const RightArrowSvg = ({ handleClick }: IArrow) => {
  return (
    <div onClick={handleClick}>
      <svg
        id="Group_23703"
        data-name="Group 23703"
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="42"
        viewBox="0 0 47 42"
      >
        <rect
          id="Rectangle_18481"
          data-name="Rectangle 18481"
          width="47"
          height="42"
          fill="#ae0a6f"
        />
        <path
          id="arrow-return-left"
          d="M4.5,6.75V18.556l7.871-5.9Z"
          transform="translate(14.629 8.25)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

// =============================================

export const LeftArrowSvg = ({ handleClick }: IArrow) => {
  return (
    <div onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="42"
        viewBox="0 0 47 42"
      >
        <g
          id="Group_23702"
          data-name="Group 23702"
          transform="translate(-67 -597)"
        >
          <rect
            id="Rectangle_18481"
            data-name="Rectangle 18481"
            width="47"
            height="42"
            transform="translate(67 597)"
            fill="#ae0a6f"
          />
          <path
            id="arrow-return-left"
            d="M12.371,6.75V18.556L4.5,12.653Z"
            transform="translate(82.5 605.25)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
};

// ==========================================
export const CircleSvg = (props: IColor) => {
  const { color } = props;
  return (
    <svg width={18} height={18}>
      <circle r={9} cx={9} cy={9} fill={color} />;
    </svg>
  );
};
// ==========================================
export const ArabicFlagSVG = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30px"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
      >
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,371.000000 
	C513.000000,418.303345 513.000000,465.606689 513.000000,512.955017 
	C342.333344,512.955017 171.666672,512.955017 1.000000,512.955017 
	C1.000000,500.922150 1.000000,488.929718 1.350629,476.581665 
	C3.818024,483.911469 12.826169,490.690430 20.400999,489.883484 
	C33.031631,488.537964 39.953197,479.699066 39.981014,468.467377 
	C40.057259,437.680511 40.042355,406.893372 40.526192,376.060547 
	C193.434158,376.010406 345.879456,375.986755 498.324738,376.077484 
	C503.989075,376.080841 509.022614,375.350006 513.000000,371.000000 
z"
        />
        <path
          fill="#474756"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,370.531342 
	C509.022614,375.350006 503.989075,376.080841 498.324738,376.077484 
	C345.879456,375.986755 193.434158,376.010406 40.352699,375.671509 
	C39.483040,374.040497 39.045967,372.752808 39.045723,371.465027 
	C39.039211,337.247559 39.077721,303.030090 39.550396,268.962646 
	C41.818069,269.183746 43.643913,269.316864 45.469765,269.316986 
	C199.328369,269.326538 353.186951,269.327026 507.045563,269.312317 
	C509.030365,269.312134 511.015198,269.108643 513.000000,269.000000 
	C513.000000,302.687561 513.000000,336.375122 513.000000,370.531342 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,62.000000 
	C513.000000,95.354225 513.000000,128.708450 512.537476,162.615341 
	C509.743713,163.181839 507.412445,163.207596 505.081146,163.207779 
	C349.868652,163.220673 194.656143,163.231522 39.330055,162.767120 
	C39.190479,128.591156 39.152988,94.890587 39.178497,61.190075 
	C39.179573,59.768040 39.766220,58.346447 40.542755,56.956009 
	C193.678741,56.991585 346.352051,56.995804 499.025360,57.000050 
	C500.357300,57.000084 501.861908,56.595650 502.988861,57.076412 
	C506.404419,58.533566 509.673706,60.333565 513.000000,62.000000 
z"
        />
        <path
          fill="#F5F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M39.443657,163.242538 
	C194.656143,163.231522 349.868652,163.220673 505.081146,163.207779 
	C507.412445,163.207596 509.743713,163.181839 512.537476,163.084000 
	C513.000000,198.020889 513.000000,233.041794 513.000000,268.531372 
	C511.015198,269.108643 509.030365,269.312134 507.045563,269.312317 
	C353.186951,269.327026 199.328369,269.326538 45.469765,269.316986 
	C43.643913,269.316864 41.818069,269.183746 39.574600,268.506897 
	C39.159321,241.647415 39.139256,215.393692 39.176750,189.140060 
	C39.189079,180.507446 39.350441,171.875046 39.443657,163.242538 
M288.623077,236.212799 
	C290.969086,238.939270 293.315094,241.665756 295.661102,244.392227 
	C296.138245,244.032410 296.615417,243.672577 297.092560,243.312759 
	C297.092560,228.563797 297.242401,213.812286 296.990112,199.067642 
	C296.924805,195.251694 293.302063,193.306976 289.245087,194.235779 
	C287.012115,194.747009 284.854736,195.588394 282.426941,196.355606 
	C281.281891,191.636246 280.552582,187.193817 279.103607,183.000046 
	C277.002228,176.918076 271.911987,174.572449 263.152679,174.767578 
	C257.515259,174.893173 256.669708,179.724258 253.288208,183.374817 
	C256.858276,183.638336 259.275116,183.816727 262.342316,184.043121 
	C261.421600,188.366241 260.588531,192.277710 259.697235,196.462769 
	C257.285583,195.777496 255.533997,195.302948 253.796356,194.781860 
	C247.462509,192.882477 244.139481,195.219818 244.018234,201.810196 
	C243.926285,206.808441 243.999420,211.809723 243.999359,216.809662 
	C243.999252,225.708099 243.999329,234.606552 243.999329,244.556793 
	C247.694916,241.043320 250.540909,238.337555 253.386917,235.631805 
	C253.686005,235.900146 253.985107,236.168488 254.284195,236.436829 
	C250.683334,240.729202 254.008331,249.755951 244.299744,249.641388 
	C244.299744,252.493958 244.299744,255.078186 244.299744,257.780273 
	C262.004608,257.780273 279.407684,257.780273 296.954651,257.780273 
	C296.954651,254.876511 296.954651,252.299774 296.954651,250.240402 
	C294.706451,249.142075 291.935669,248.702835 291.306122,247.301590 
	C289.709229,243.747131 289.072235,239.761414 288.011292,235.991699 
	C288.011292,235.991699 287.974915,236.037689 288.623077,236.212799 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,61.531342 
	C509.673706,60.333565 506.404419,58.533566 502.988861,57.076412 
	C501.861908,56.595650 500.357300,57.000084 499.025360,57.000050 
	C346.352051,56.995804 193.678741,56.991585 40.506729,56.494736 
	C39.671886,50.293636 39.858646,44.493774 38.880531,38.897514 
	C37.301899,29.865419 27.108122,23.921425 18.295607,24.683729 
	C9.206715,25.469938 4.641684,30.817272 1.000000,38.000000 
	C1.000000,25.675512 1.000000,13.351025 1.000000,1.052943 
	C171.666672,1.052943 342.333344,1.052943 513.000000,1.052943 
	C513.000000,21.091486 513.000000,41.077084 513.000000,61.531342 
z"
        />
        <path
          fill="#2E3033"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,38.468658 
	C4.641684,30.817272 9.206715,25.469938 18.295607,24.683729 
	C27.108122,23.921425 37.301899,29.865419 38.880531,38.897514 
	C39.858646,44.493774 39.671886,50.293636 40.044044,56.463379 
	C39.766220,58.346447 39.179573,59.768040 39.178497,61.190075 
	C39.152988,94.890587 39.190479,128.591156 39.330055,162.767120 
	C39.350441,171.875046 39.189079,180.507446 39.176750,189.140060 
	C39.139256,215.393692 39.159321,241.647415 39.132774,268.356873 
	C39.077721,303.030090 39.039211,337.247559 39.045723,371.465027 
	C39.045967,372.752808 39.483040,374.040497 39.890041,375.717285 
	C40.042355,406.893372 40.057259,437.680511 39.981014,468.467377 
	C39.953197,479.699066 33.031631,488.537964 20.400999,489.883484 
	C12.826169,490.690430 3.818024,483.911469 1.350629,476.112976 
	C1.000000,330.312439 1.000000,184.624878 1.000000,38.468658 
z"
        />
        <path
          fill="#F0C82A"
          opacity="1.000000"
          stroke="none"
          d="
M288.050873,235.948380 
	C289.072235,239.761414 289.709229,243.747131 291.306122,247.301590 
	C291.935669,248.702835 294.706451,249.142075 296.954651,250.240402 
	C296.954651,252.299774 296.954651,254.876511 296.954651,257.780273 
	C279.407684,257.780273 262.004608,257.780273 244.299744,257.780273 
	C244.299744,255.078186 244.299744,252.493958 244.299744,249.641388 
	C254.008331,249.755951 250.683334,240.729202 254.284195,236.436829 
	C253.985107,236.168488 253.686005,235.900146 253.386917,235.631805 
	C250.540909,238.337555 247.694916,241.043320 243.999329,244.556793 
	C243.999329,234.606552 243.999252,225.708099 243.999359,216.809662 
	C243.999420,211.809723 243.926285,206.808441 244.018234,201.810196 
	C244.139481,195.219818 247.462509,192.882477 253.796356,194.781860 
	C255.533997,195.302948 257.285583,195.777496 259.697235,196.462769 
	C260.588531,192.277710 261.421600,188.366241 262.342316,184.043121 
	C259.275116,183.816727 256.858276,183.638336 253.288208,183.374817 
	C256.669708,179.724258 257.515259,174.893173 263.152679,174.767578 
	C271.911987,174.572449 277.002228,176.918076 279.103607,183.000046 
	C280.552582,187.193817 281.281891,191.636246 282.426941,196.355606 
	C284.854736,195.588394 287.012115,194.747009 289.245087,194.235779 
	C293.302063,193.306976 296.924805,195.251694 296.990112,199.067642 
	C297.242401,213.812286 297.092560,228.563797 297.092560,243.312759 
	C296.615417,243.672577 296.138245,244.032410 295.661102,244.392227 
	C293.315094,241.665756 290.969086,238.939270 288.247314,235.930298 
	C287.706665,235.677094 287.541779,235.706406 287.376892,235.735733 
	C287.601562,235.806625 287.826202,235.877502 288.050873,235.948380 
M282.144073,217.419144 
	C282.712494,214.668869 283.468842,211.937469 283.748260,209.158142 
	C283.847107,208.175034 283.055298,206.656662 282.202057,206.099869 
	C278.269592,203.533630 274.171967,201.220520 270.585907,199.085907 
	C267.131866,201.274582 263.467102,203.454849 259.983856,205.894241 
	C258.996216,206.585922 257.923309,208.038498 257.932434,209.133392 
	C258.017456,219.321625 263.047668,226.858948 271.802460,233.885864 
	C275.398529,228.314423 278.671021,223.244278 282.144073,217.419144 
M275.061127,246.318268 
	C275.667023,251.707840 279.291931,248.305374 282.305420,249.143448 
	C280.569122,244.616806 279.106842,240.804520 277.644562,236.992249 
	C277.033325,237.053345 276.422119,237.114441 275.810883,237.175552 
	C275.559509,239.923126 275.308136,242.670685 275.061127,246.318268 
M266.160370,241.832428 
	C265.783203,240.259018 265.406006,238.685623 265.028839,237.112213 
	C264.593231,237.110840 264.157623,237.109482 263.722046,237.108109 
	C262.303162,240.838303 260.884308,244.568497 259.254822,248.852448 
	C261.941833,248.852448 263.802032,248.852448 266.160950,248.852448 
	C266.160950,246.620224 266.160950,244.673737 266.160370,241.832428 
z"
        />
        <path
          fill="#F5F4F1"
          opacity="1.000000"
          stroke="none"
          d="
M282.043793,217.796631 
	C278.671021,223.244278 275.398529,228.314423 271.802460,233.885864 
	C263.047668,226.858948 258.017456,219.321625 257.932434,209.133392 
	C257.923309,208.038498 258.996216,206.585922 259.983856,205.894241 
	C263.467102,203.454849 267.131866,201.274582 270.585907,199.085907 
	C274.171967,201.220520 278.269592,203.533630 282.202057,206.099869 
	C283.055298,206.656662 283.847107,208.175034 283.748260,209.158142 
	C283.468842,211.937469 282.712494,214.668869 282.043793,217.796631 
z"
        />
        <path
          fill="#F5F2E9"
          opacity="1.000000"
          stroke="none"
          d="
M275.058960,245.868256 
	C275.308136,242.670685 275.559509,239.923126 275.810883,237.175552 
	C276.422119,237.114441 277.033325,237.053345 277.644562,236.992249 
	C279.106842,240.804520 280.569122,244.616806 282.305420,249.143448 
	C279.291931,248.305374 275.667023,251.707840 275.058960,245.868256 
z"
        />
        <path
          fill="#F5F4EF"
          opacity="1.000000"
          stroke="none"
          d="
M266.160645,242.279831 
	C266.160950,244.673737 266.160950,246.620224 266.160950,248.852448 
	C263.802032,248.852448 261.941833,248.852448 259.254822,248.852448 
	C260.884308,244.568497 262.303162,240.838303 263.722046,237.108109 
	C264.157623,237.109482 264.593231,237.110840 265.028839,237.112213 
	C265.406006,238.685623 265.783203,240.259018 266.160645,242.279831 
z"
        />
        <path
          fill="#F5F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M288.031067,235.970032 
	C287.826202,235.877502 287.601562,235.806625 287.376892,235.735733 
	C287.541779,235.706406 287.706665,235.677094 287.923218,235.842743 
	C287.974915,236.037689 288.011292,235.991699 288.031067,235.970032 
z"
        />
      </svg>
    </div>
  );
};
// ==========================================
export const EnglishFlagSVG = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30px"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
      >
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,371.000000 
	C513.000000,418.303436 513.000000,465.606903 513.000000,512.955200 
	C342.333344,512.955200 171.666672,512.955200 1.000000,512.955200 
	C1.000000,500.254700 1.000000,487.596008 1.342041,474.571472 
	C5.571120,482.602936 14.868176,488.502289 22.037327,486.944885 
	C32.746593,484.618439 39.356613,476.936798 39.182632,465.984985 
	C38.700985,435.665771 39.087669,405.332703 39.590454,374.991760 
	C192.943008,374.976196 345.836243,374.956421 498.729462,375.045624 
	C504.017822,375.048706 508.906525,374.707367 513.000000,371.000000 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,40.000000 
	C1.000000,27.009293 1.000000,14.018586 1.000000,1.052883 
	C171.666672,1.052883 342.333344,1.052883 513.000000,1.052883 
	C513.000000,21.091406 513.000000,41.077045 512.651123,61.466888 
	C510.102356,57.372475 506.099731,57.036446 501.747070,57.038689 
	C423.047333,57.079250 344.347595,57.067123 265.647827,57.074318 
	C264.319611,57.074436 262.991394,57.174496 261.231384,57.169903 
	C259.969086,57.075226 259.138519,57.006546 258.307983,57.006470 
	C187.130646,56.999855 115.953300,56.993763 44.775967,57.024105 
	C42.976036,57.024872 41.176273,57.430798 39.224697,57.160042 
	C39.048851,52.513329 39.031799,48.354282 38.999249,44.195354 
	C38.917458,33.746357 26.091082,25.416029 16.881865,27.427076 
	C9.299194,29.082928 4.558550,33.620895 1.000000,40.000000 
z"
        />
        <path
          fill="#2E3033"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,40.468658 
	C4.558550,33.620895 9.299194,29.082928 16.881865,27.427076 
	C26.091082,25.416029 38.917458,33.746357 38.999249,44.195354 
	C39.031799,48.354282 39.048851,52.513329 39.055241,57.529762 
	C39.024017,116.215584 39.010513,174.043961 38.946945,232.346619 
	C38.965660,239.450943 39.034435,246.081009 39.024807,253.192444 
	C38.993626,261.451141 39.040844,269.228485 39.000801,277.466980 
	C38.962906,285.741791 39.012283,293.555420 38.993988,301.810120 
	C38.979950,310.159790 39.033585,318.068359 39.000401,326.441772 
	C38.962940,334.727417 39.012295,342.548218 38.993977,350.810120 
	C38.994579,359.169159 39.062855,367.087158 39.131134,375.005127 
	C39.087669,405.332703 38.700985,435.665771 39.182632,465.984985 
	C39.356613,476.936798 32.746593,484.618439 22.037327,486.944885 
	C14.868176,488.502289 5.571120,482.602936 1.342041,474.102814 
	C1.000000,329.645782 1.000000,185.291550 1.000000,40.468658 
z"
        />
        <path
          fill="#FF4F59"
          opacity="1.000000"
          stroke="none"
          d="
M39.087219,325.976959 
	C39.033585,318.068359 38.979950,310.159790 39.456482,301.806335 
	C195.341141,301.360260 350.695648,301.360565 506.050140,301.342957 
	C508.366760,301.342712 510.683380,301.119263 513.000000,301.000000 
	C513.000000,309.020905 513.000000,317.041809 512.531494,325.517944 
	C354.404388,325.974426 196.745804,325.975677 39.087219,325.976959 
z"
        />
        <path
          fill="#FF4F59"
          opacity="1.000000"
          stroke="none"
          d="
M39.590454,374.991760 
	C39.062855,367.087158 38.994579,359.169159 39.456474,350.806335 
	C195.341278,350.360291 350.695892,350.360596 506.050537,350.342987 
	C508.367035,350.342743 510.683502,350.119293 513.000000,350.000000 
	C513.000000,356.687653 513.000000,363.375275 513.000000,370.531464 
	C508.906525,374.707367 504.017822,375.048706 498.729462,375.045624 
	C345.836243,374.956421 192.943008,374.976196 39.590454,374.991760 
z"
        />
        <path
          fill="#F5F5F5"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,349.531372 
	C510.683502,350.119293 508.367035,350.342743 506.050537,350.342987 
	C350.695892,350.360596 195.341278,350.360291 39.524147,350.365265 
	C39.012295,342.548218 38.962940,334.727417 39.000401,326.441772 
	C196.745804,325.975677 354.404388,325.974426 512.531494,325.986572 
	C513.000000,333.687592 513.000000,341.375153 513.000000,349.531372 
z"
        />
        <path
          fill="#F5F5F5"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,300.531372 
	C510.683380,301.119263 508.366760,301.342712 506.050140,301.342957 
	C350.695648,301.360565 195.341141,301.360260 39.524155,301.365265 
	C39.012283,293.555420 38.962906,285.741791 39.462936,277.464966 
	C197.674896,277.001190 355.337433,277.000580 513.000000,277.000000 
	C513.000000,284.687592 513.000000,292.375153 513.000000,300.531372 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,276.531372 
	C355.337433,277.000580 197.674896,277.001190 39.550201,277.003784 
	C39.040844,269.228485 38.993626,261.451141 39.487762,253.201935 
	C195.527588,252.733612 351.026062,252.736145 506.524536,252.751495 
	C508.683014,252.751709 510.841522,252.913559 513.000000,253.000000 
	C513.000000,260.687592 513.000000,268.375153 513.000000,276.531372 
z"
        />
        <path
          fill="#F5F1F1"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,252.531342 
	C510.841522,252.913559 508.683014,252.751709 506.524536,252.751495 
	C351.026062,252.736145 195.527588,252.733612 39.566170,252.720566 
	C39.034435,246.081009 38.965660,239.450943 39.362396,232.408340 
	C42.114193,232.329529 44.400265,232.953171 46.686794,232.954865 
	C116.480560,233.006454 186.274399,232.972870 256.068115,233.052460 
	C260.599579,233.057632 264.212189,231.858627 267.286865,228.114746 
	C348.349152,228.165451 428.932343,228.172882 509.515503,228.171356 
	C510.677002,228.171341 511.838501,228.059601 513.000000,228.000000 
	C513.000000,236.020905 513.000000,244.041809 513.000000,252.531342 
z"
        />
        <path
          fill="#FE4F59"
          opacity="1.000000"
          stroke="none"
          d="
M261.663177,57.227928 
	C262.991394,57.174496 264.319611,57.074436 265.647827,57.074318 
	C344.347595,57.067123 423.047333,57.079250 501.747070,57.038689 
	C506.099731,57.036446 510.102356,57.372475 512.651123,61.935547 
	C513.000000,68.354401 513.000000,74.708794 512.541443,81.423920 
	C439.870422,81.769310 367.657990,81.749077 295.445526,81.744682 
	C286.129395,81.744118 276.813293,81.825569 267.414062,81.421753 
	C267.301086,76.646072 267.086426,72.308937 267.290680,67.991615 
	C267.517303,63.201111 266.006531,59.527485 261.663177,57.227928 
z"
        />
        <path
          fill="#F5F3F3"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,179.000000 
	C513.000000,187.020905 513.000000,195.041809 512.531616,203.513855 
	C430.545776,203.960907 349.028412,203.956818 267.438660,203.491806 
	C267.414825,195.047134 267.463348,187.063370 267.948273,179.092499 
	C308.655273,179.102753 348.925873,179.111740 389.196472,179.094254 
	C430.464325,179.076340 471.732147,179.032501 513.000000,179.000000 
z"
        />
        <path
          fill="#F5F1F1"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,130.000000 
	C513.000000,138.020905 513.000000,146.041809 512.532593,154.493820 
	C430.547516,154.924927 349.029846,154.924911 267.443298,154.465668 
	C267.420776,146.077255 267.467133,138.148071 267.949097,130.241592 
	C347.934326,130.262787 427.483917,130.263153 507.033508,130.248901 
	C509.022339,130.248550 511.011169,130.086594 513.000000,130.000000 
z"
        />
        <path
          fill="#F5F5F5"
          opacity="1.000000"
          stroke="none"
          d="
M267.497162,81.869026 
	C276.813293,81.825569 286.129395,81.744118 295.445526,81.744682 
	C367.657990,81.749077 439.870422,81.769310 512.541443,81.892319 
	C513.000000,89.687576 513.000000,97.375153 512.531372,105.531532 
	C430.545013,105.992615 349.027313,105.984901 267.433350,105.515060 
	C267.403748,97.324974 267.450470,89.597000 267.497162,81.869026 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M267.511017,203.952728 
	C349.028412,203.956818 430.545776,203.960907 512.531616,203.982498 
	C513.000000,211.687576 513.000000,219.375168 513.000000,227.531372 
	C511.838501,228.059601 510.677002,228.171341 509.515503,228.171356 
	C428.932343,228.172882 348.349152,228.165451 267.344696,227.641785 
	C267.119293,219.400757 267.315155,211.676743 267.511017,203.952728 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M267.512177,154.924896 
	C349.029846,154.924911 430.547516,154.924927 512.532593,154.962463 
	C513.000000,162.687576 513.000000,170.375168 513.000000,178.531372 
	C471.732147,179.032501 430.464325,179.076340 389.196472,179.094254 
	C348.925873,179.111740 308.655273,179.102753 267.855103,178.627243 
	C267.387756,170.407700 267.449951,162.666290 267.512177,154.924896 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M267.509644,105.977180 
	C349.027313,105.984901 430.545013,105.992615 512.531372,106.000168 
	C513.000000,113.687584 513.000000,121.375160 513.000000,129.531372 
	C511.011169,130.086594 509.022339,130.248550 507.033508,130.248901 
	C427.483917,130.263153 347.934326,130.262787 267.857239,129.762207 
	C267.389709,121.499138 267.449677,113.738159 267.509644,105.977180 
z"
        />
        <path
          fill="#43499C"
          opacity="1.000000"
          stroke="none"
          d="
M267.433350,105.515060 
	C267.449677,113.738159 267.389709,121.499138 267.421600,129.739502 
	C267.467133,138.148071 267.420776,146.077255 267.443298,154.465668 
	C267.449951,162.666290 267.387756,170.407700 267.418701,178.614349 
	C267.463348,187.063370 267.414825,195.047134 267.438660,203.491806 
	C267.315155,211.676743 267.119293,219.400757 266.865570,227.597733 
	C264.212189,231.858627 260.599579,233.057632 256.068115,233.052460 
	C186.274399,232.972870 116.480560,233.006454 46.686794,232.954865 
	C44.400265,232.953171 42.114193,232.329529 39.412457,231.934082 
	C39.010513,174.043961 39.024017,116.215584 39.206978,58.017483 
	C41.176273,57.430798 42.976036,57.024872 44.775967,57.024105 
	C115.953300,56.993763 187.130646,56.999855 258.307983,57.006470 
	C259.138519,57.006546 259.969086,57.075226 261.231384,57.169907 
	C266.006531,59.527485 267.517303,63.201111 267.290680,67.991615 
	C267.086426,72.308937 267.301086,76.646072 267.414062,81.421753 
	C267.450470,89.597000 267.403748,97.324974 267.433350,105.515060 
M96.365479,82.600983 
	C97.804604,86.221031 102.297203,88.997444 98.856544,94.680534 
	C103.086716,89.355309 106.858994,91.677643 110.945007,94.024971 
	C108.487221,89.112068 110.293243,85.871170 114.381882,82.828651 
	C109.097198,83.304260 106.029892,81.153831 104.804054,75.911934 
	C103.402962,80.180710 101.836975,83.344566 96.365479,82.600983 
M50.060211,84.468170 
	C55.010517,86.195160 53.145393,90.337799 52.926174,94.309669 
	C55.149315,92.974998 56.821712,91.970978 58.575161,90.918289 
	C60.382999,91.987816 62.156193,93.036850 64.731209,94.560242 
	C64.098564,91.614174 63.666512,89.602219 63.245388,87.641144 
	C64.836853,86.178978 66.340591,84.797417 67.844322,83.415855 
	C62.648205,83.432419 59.768368,81.017647 58.493038,75.942276 
	C57.071064,80.526283 54.952526,83.219826 50.261398,82.488319 
	C50.106850,82.464218 49.797924,83.430099 50.060211,84.468170 
M244.382278,76.670464 
	C242.107101,79.964577 240.372192,84.018112 234.497482,82.745300 
	C239.070618,85.848175 240.231323,89.327431 238.149033,94.342789 
	C242.172607,90.676605 245.922668,90.529846 249.982880,94.318741 
	C248.023621,89.304283 249.226456,85.835869 253.584015,82.762787 
	C248.695404,83.159691 245.557129,81.811256 244.382278,76.670464 
M150.692825,76.996346 
	C149.893829,81.802277 146.305939,82.784500 140.967789,82.603851 
	C143.541138,84.833191 145.111557,86.193665 146.761108,87.622704 
	C146.334244,89.612038 145.902115,91.625847 145.273544,94.555168 
	C147.848358,93.032860 149.629288,91.979935 151.419891,90.921272 
	C153.193176,91.976753 154.870468,92.975090 156.547745,93.973434 
	C154.927338,89.269112 156.612534,85.846275 160.699661,82.807915 
	C155.093948,83.628891 153.143402,80.327888 150.692825,76.996346 
M251.091293,112.175545 
	C246.182022,113.531586 246.002701,108.965042 243.729950,105.383499 
	C243.104187,111.469818 239.556030,112.935928 236.227142,112.553246 
	C237.372635,116.419525 238.408554,119.915947 239.485382,123.550407 
	C242.184280,120.653870 245.924057,120.337364 249.894150,124.301491 
	C248.777267,119.856049 248.290970,116.208496 252.635330,114.000290 
	C252.673859,113.980705 252.147919,112.850647 251.091293,112.175545 
M150.117569,108.067947 
	C150.308121,113.833069 144.645370,111.105736 142.006882,113.309883 
	C143.831787,114.932838 145.395432,116.323456 146.834335,117.603134 
	C146.315140,119.775925 145.868973,121.643074 145.020584,125.193436 
	C149.540176,120.677330 153.265045,120.443001 157.386612,124.184456 
	C154.933533,119.122330 156.457367,115.663589 161.119919,112.519142 
	C155.335709,113.675797 153.085754,110.886635 151.859360,106.710152 
	C151.372055,106.934219 150.884766,107.158287 150.117569,108.067947 
M103.254898,109.090523 
	C102.934364,114.121246 97.980270,110.918816 95.293541,113.597641 
	C100.674103,115.512459 100.468460,119.545174 99.227982,124.238655 
	C103.299263,119.855583 106.910225,121.281212 111.014671,124.092606 
	C108.395821,119.143677 110.237564,115.744476 114.335320,112.687897 
	C108.925751,113.234200 106.100792,111.014801 105.154305,105.240944 
	C104.237541,107.060898 103.901573,107.727852 103.254898,109.090523 
M194.995331,92.590446 
	C198.257080,88.479576 200.520386,94.402298 204.046860,93.575974 
	C201.058090,88.930557 203.318008,85.865761 207.185410,82.800064 
	C201.996536,83.407547 199.071884,81.081284 197.463943,76.262047 
	C196.855743,82.215134 192.625107,82.377052 187.724701,82.868767 
	C191.688965,85.803497 194.222504,88.476685 191.918915,92.693375 
	C192.739319,92.802582 193.559738,92.911781 194.995331,92.590446 
M62.749290,142.056396 
	C61.300098,140.180161 59.850903,138.303909 57.362206,135.081848 
	C58.429295,142.622589 53.411781,141.935791 47.694725,142.837036 
	C54.039265,145.479080 54.621147,149.236542 52.705605,154.140839 
	C56.800823,149.904343 60.542786,150.981186 65.095680,155.150757 
	C64.129227,151.229416 63.633789,149.219193 63.186775,147.405472 
	C64.954697,145.815811 66.443817,144.476852 68.740425,142.411804 
	C66.115112,142.239258 64.828758,142.154694 62.749290,142.056396 
M104.115692,136.979080 
	C103.888046,142.214417 99.537949,142.226089 94.178032,142.934784 
	C100.365234,145.770752 100.499901,149.570175 99.123184,154.259979 
	C103.071426,150.101624 106.820816,150.783813 111.005310,154.041382 
	C107.969223,148.748520 110.889313,145.631119 114.968651,142.436447 
	C111.501343,142.126663 109.408943,141.939728 108.029999,141.816528 
	C106.643379,139.638000 105.636452,138.056000 104.115692,136.979080 
M188.949295,172.401382 
	C191.136002,175.790131 194.686203,178.745987 191.891541,184.012177 
	C196.065857,179.819321 199.683563,180.972290 203.679199,183.863861 
	C201.554703,179.002487 202.758423,175.453506 207.197372,172.469131 
	C201.651627,173.257324 198.886780,170.752747 197.762329,165.641617 
	C196.272415,170.114456 194.470398,173.205170 188.949295,172.401382 
M59.881306,108.066986 
	C59.458557,107.488159 59.035805,106.909332 57.304867,104.539345 
	C58.397751,112.617722 53.467525,112.064430 47.603260,112.946701 
	C54.004002,115.539337 54.610619,119.300072 51.992359,124.813995 
	C55.112686,122.910309 56.773548,121.897026 58.550503,120.812920 
	C60.347988,121.877747 62.135777,122.936829 64.718719,124.466957 
	C64.100754,121.615303 63.667191,119.614594 63.206017,117.486465 
	C64.727509,116.140282 66.280693,114.766060 67.986626,113.256683 
	C65.521080,110.883469 60.269905,114.243530 59.881306,108.066986 
M249.077728,149.321091 
	C250.296936,147.082047 251.516144,144.843002 253.017731,142.085373 
	C247.820602,143.440750 245.540451,140.439178 244.700867,137.443832 
	C241.640167,139.592941 238.703506,141.654953 235.494766,143.908005 
	C239.258102,145.660034 240.089844,149.356049 238.057251,154.216232 
	C242.185760,150.626556 245.795380,150.243256 250.306717,155.248093 
	C249.744720,152.417709 249.511932,151.245346 249.077728,149.321091 
M147.174789,153.406937 
	C150.378357,149.486404 153.660278,152.059021 157.473938,153.651474 
	C154.374268,148.613052 157.392166,145.590073 160.821472,142.392105 
	C155.098373,143.521988 152.841919,140.490158 151.529953,135.187851 
	C150.199814,138.223236 149.368805,140.119598 148.599991,141.874023 
	C146.268402,142.072372 144.284714,142.241119 141.275253,142.497131 
	C143.806961,144.759094 145.374023,146.159195 146.103455,146.810913 
	C146.306595,149.753510 146.444962,151.757767 147.174789,153.406937 
M194.977737,122.501404 
	C198.238541,118.481911 200.539474,124.202637 204.110382,123.550514 
	C201.015671,118.838135 203.268677,115.769524 207.155991,112.771820 
	C201.829529,113.216080 198.807129,110.977425 197.591415,105.712448 
	C196.809235,111.393402 193.248337,112.734940 188.332840,112.983681 
	C192.906662,115.699944 193.569778,119.315735 191.379776,124.293182 
	C193.010086,123.560120 193.691650,123.253670 194.977737,122.501404 
M127.683426,105.917679 
	C129.648468,106.835648 131.613510,107.753609 135.350021,109.499107 
	C131.074066,103.708618 134.016083,100.567543 137.386841,97.233704 
	C131.566864,98.649178 129.673523,95.043030 127.758614,90.921547 
	C127.280586,96.747879 123.377312,97.488373 118.538048,97.823288 
	C123.758141,100.370552 123.885353,104.270470 121.291603,109.848740 
	C124.244331,107.955330 125.617943,107.074509 127.683426,105.917679 
M222.006119,106.192894 
	C223.423050,107.100029 224.839981,108.007164 226.256912,108.914299 
	C224.123703,103.692917 226.614441,100.478004 231.131073,97.390717 
	C227.529663,97.081505 225.432312,96.901428 223.631607,96.746826 
	C222.712585,94.615547 221.965668,92.883385 221.218750,91.151215 
	C219.886658,96.211014 216.589355,97.781586 211.321884,97.779831 
	C216.777679,100.308846 216.285507,104.428040 214.693634,109.042122 
	C217.524750,107.705490 219.418686,106.811317 222.006119,106.192894 
M76.588303,105.027603 
	C76.397537,106.186081 76.206772,107.344559 76.016006,108.503044 
	C76.214813,109.018867 76.413620,109.534691 76.612427,110.050514 
	C80.138710,103.616005 83.789314,106.811775 88.626801,109.520172 
	C85.502335,103.955986 87.034531,100.492516 91.288773,97.389175 
	C85.558937,98.508049 83.155106,95.467606 82.009041,90.088928 
	C80.566589,93.115570 80.155029,95.493317 78.757561,96.493385 
	C77.306412,97.531883 74.901390,97.237503 73.107574,97.497849 
	C74.492439,100.019974 75.664719,102.154945 76.588303,105.027603 
M244.399567,166.345673 
	C242.061325,169.482239 240.708557,174.020294 234.283051,172.450180 
	C239.649857,175.372055 240.106308,179.176895 238.111221,184.421463 
	C242.185989,179.350388 245.971008,181.132889 250.096344,183.715851 
	C247.726715,178.867813 249.497330,175.572067 254.478851,172.240128 
	C248.920380,172.475250 245.423157,171.701279 244.399567,166.345673 
M106.627327,181.614426 
	C107.846474,182.355515 109.065620,183.096603 110.284775,183.837708 
	C108.510071,178.982147 110.018982,175.493454 114.409103,172.410294 
	C108.601501,173.572296 106.298805,170.387192 104.445778,165.490936 
	C104.706505,172.473373 99.695503,171.892792 94.482475,172.832306 
	C100.398865,175.569046 100.534546,179.377945 98.776665,183.695023 
	C101.575348,182.726410 103.790939,181.959610 106.627327,181.614426 
M203.597076,152.219376 
	C200.725372,147.855621 204.448776,145.597763 207.949905,142.014999 
	C201.744400,143.235153 198.887756,140.814697 197.557678,135.701782 
	C196.794312,141.386475 193.217133,142.676636 189.844101,142.542587 
	C190.997452,146.262177 192.082809,149.762543 193.221481,153.434830 
	C195.748093,150.117706 199.162674,150.648178 202.581345,153.679840 
	C202.658569,153.748306 203.279343,153.203827 203.597076,152.219376 
M52.740276,172.024902 
	C51.705658,172.168427 50.671043,172.311935 49.636425,172.455444 
	C54.113068,175.319443 54.419922,179.178833 52.949108,183.957947 
	C56.976120,179.768250 60.566830,181.031784 64.499626,183.810547 
	C62.298717,178.912613 63.667881,175.445923 69.147095,172.496628 
	C65.486542,172.165802 63.443539,171.981155 61.389786,171.795547 
	C60.522957,169.840347 59.695587,167.974152 59.072121,166.567886 
	C57.103611,168.499451 55.308094,170.261276 52.740276,172.024902 
M173.592697,92.374809 
	C173.378433,97.661644 168.714737,96.725494 164.375290,97.944374 
	C169.963684,100.416153 170.463455,104.083015 168.904617,109.024742 
	C172.843063,104.713997 176.549454,105.896172 181.007080,110.041512 
	C180.099564,106.197014 179.624649,104.185165 179.182953,102.313995 
	C180.893051,100.753365 182.386292,99.390625 184.574005,97.394119 
	C181.388840,97.127022 179.317688,96.953339 178.100388,96.851257 
	C176.430344,94.782944 175.189392,93.246048 173.592697,92.374809 
M145.649078,172.024216 
	C144.581970,172.136322 143.514862,172.248428 140.635834,172.550888 
	C146.658585,175.400055 147.422226,179.091110 144.835892,184.520569 
	C147.870987,182.751450 149.669907,181.702881 151.440979,180.670547 
	C153.255173,181.734390 154.934235,182.718994 156.613281,183.703583 
	C154.742416,178.826508 156.733856,175.470917 161.013641,172.245071 
	C154.912262,173.760559 152.907715,170.369324 151.878204,166.487839 
	C149.979828,168.417999 148.207733,170.219757 145.649078,172.024216 
M196.315521,197.989746 
	C196.429199,203.400650 191.172760,201.005096 187.866638,203.443314 
	C194.014862,205.034592 192.907806,209.105469 191.675140,213.873978 
	C194.196854,212.432388 195.857391,211.483109 197.585861,210.494995 
	C199.378952,211.443527 201.195801,212.404633 203.012665,213.365753 
	C200.540421,208.255859 203.666779,205.473175 208.074081,201.992783 
	C202.352676,202.652756 199.047668,201.417618 198.391724,196.586105 
	C197.808578,196.825439 197.225418,197.064774 196.315521,197.989746 
M133.580780,126.926682 
	C131.614044,125.224380 129.647308,123.522072 127.559456,121.714943 
	C126.930130,125.853706 123.769905,127.798462 118.669426,127.641853 
	C123.495621,130.327148 123.965790,133.970886 122.259590,138.879333 
	C126.147560,134.681534 129.916214,135.532944 134.042709,138.421600 
	C131.418304,133.358307 133.969986,130.319443 138.008530,127.290985 
	C135.997498,127.089516 135.200439,127.009666 133.580780,126.926682 
M219.058411,136.444290 
	C221.549652,136.900986 224.040909,137.357666 226.881927,137.878464 
	C224.597412,133.698914 226.024338,130.203827 230.424240,127.307777 
	C224.684967,128.298859 222.337677,125.396431 220.829941,120.739563 
	C219.659195,125.719124 216.907501,127.986771 212.909042,127.239426 
	C214.096497,130.763657 215.256332,134.205872 216.484711,137.851532 
	C216.477524,137.854950 217.442245,137.394745 219.058411,136.444290 
M89.116333,159.776428 
	C89.725456,159.103577 90.334572,158.430725 92.222191,156.345642 
	C85.398613,158.586166 83.206490,155.232391 81.642929,150.491394 
	C80.650192,155.933777 77.447693,157.682602 72.477432,157.683395 
	C76.897186,160.335358 77.524467,163.944153 75.270935,169.143250 
	C78.410591,167.350021 80.081596,166.395630 81.632332,165.509933 
	C83.559464,166.546646 85.248192,167.455109 86.427208,168.089371 
	C87.143150,165.280182 87.788300,162.748764 89.116333,159.776428 
M130.588089,166.934708 
	C131.553528,167.394409 132.518982,167.854111 133.484421,168.313828 
	C130.789413,163.335693 134.234909,160.580063 138.229004,156.821136 
	C132.061295,158.010742 129.352997,155.678207 127.883331,150.396515 
	C127.520172,156.613937 123.487579,157.382980 118.729095,157.705170 
	C123.419601,160.336792 124.032784,163.933182 122.133865,167.895172 
	C124.950378,167.371384 127.444214,166.907623 130.588089,166.934708 
M249.695770,210.851776 
	C246.580017,206.529831 252.390259,205.388733 253.185028,201.467957 
	C247.219452,204.106079 245.915833,199.672226 243.626236,194.566406 
	C242.983932,201.092148 239.779388,202.750717 234.723785,202.512497 
	C239.315323,205.277039 240.182205,208.924667 238.146469,213.734222 
	C242.016754,210.726578 245.320511,209.921265 248.693176,213.150650 
	C249.095001,212.646317 249.496841,212.141998 249.695770,210.851776 
M108.128265,201.735413 
	C107.086960,199.834045 106.045647,197.932663 104.329872,194.799759 
	C103.907883,201.159271 100.540497,202.628265 95.270081,202.726318 
	C100.941589,205.057404 100.253731,209.097366 99.269936,213.832458 
	C103.128098,209.264313 106.921967,210.986984 111.034798,213.483582 
	C107.847824,208.280869 110.923943,205.409897 114.874588,202.176620 
	C112.116608,202.044937 110.515167,201.968475 108.128265,201.735413 
M226.836533,167.030533 
	C223.920761,162.704788 227.394058,160.312424 230.238174,157.163620 
	C224.492920,158.567932 222.380554,155.082382 220.673004,150.546127 
	C220.027878,156.358383 216.340057,157.453720 211.292038,157.843964 
	C217.087952,160.020889 216.087830,164.182297 215.363449,168.854752 
	C218.992966,164.851501 222.303726,165.358200 225.637924,168.241577 
	C225.778351,168.363007 226.410080,167.916321 226.836533,167.030533 
M86.764763,131.873184 
	C88.428268,130.729706 90.091782,129.586227 91.755287,128.442764 
	C91.483063,127.853554 91.210838,127.264351 90.938614,126.675148 
	C85.260384,128.670990 83.375450,125.090584 81.667091,120.741768 
	C80.639091,125.786682 77.727974,127.864700 72.565804,127.518074 
	C76.816673,130.345306 77.810127,133.846832 76.100426,138.777939 
	C80.063828,134.322021 83.836433,135.896500 88.229050,139.438782 
	C87.368309,136.090103 86.923615,134.360016 86.764763,131.873184 
M151.480728,210.510284 
	C153.159637,211.439880 154.838547,212.369492 157.683212,213.944565 
	C155.341675,208.750641 156.520920,205.319489 160.767334,202.795105 
	C154.623810,201.404327 154.623810,201.404327 150.923477,195.376160 
	C150.294174,200.873230 147.203110,202.896545 141.988815,202.510147 
	C146.348740,205.166046 147.410416,208.647018 145.236542,213.940155 
	C147.902679,212.415497 149.297882,211.617630 151.480728,210.510284 
M179.453064,162.019135 
	C181.134476,160.811905 182.815887,159.604660 184.497299,158.397430 
	C184.322189,157.799744 184.147079,157.202057 183.971970,156.604370 
	C178.191208,158.675903 176.078873,155.228867 174.184372,150.340942 
	C174.266159,156.993088 169.696289,156.979004 164.222702,157.628220 
	C169.761063,160.355682 170.660950,163.853622 168.117172,169.218063 
	C171.382172,167.322876 173.040329,166.360367 174.553650,165.481949 
	C176.450287,166.505753 178.064896,167.377304 180.607407,168.749725 
	C179.998047,166.013565 179.649353,164.447922 179.453064,162.019135 
M63.300407,207.880066 
	C64.763573,206.092636 66.226738,204.305191 68.559616,201.455292 
	C62.176594,203.554367 60.052620,200.352600 58.449303,195.513168 
	C57.726948,201.063522 54.375187,202.806091 49.202682,202.611847 
	C53.946579,205.342697 54.342804,208.901581 52.464737,213.870850 
	C55.147717,212.379929 56.815525,211.453125 58.536682,210.496689 
	C60.210751,211.394897 61.907055,212.305038 64.445923,213.667236 
	C64.038841,211.456085 63.786850,210.087311 63.300407,207.880066 
M87.741272,190.668503 
	C88.827950,189.683350 89.914635,188.698196 91.001312,187.713043 
	C91.061462,187.149902 91.121613,186.586746 91.181763,186.023605 
	C84.722801,189.253860 83.769730,184.179474 81.228287,179.431519 
	C80.672127,185.878571 77.505348,187.474625 72.639526,187.403030 
	C76.924706,190.175888 77.629097,193.784012 75.431740,198.920853 
	C78.323982,197.266022 80.013535,196.299316 81.771721,195.293365 
	C83.564651,196.276260 85.349442,197.254700 87.108780,198.219177 
	C87.108780,195.568130 87.108780,193.378510 87.741272,190.668503 
M176.758667,136.707123 
	C177.744049,137.193756 178.729416,137.680389 180.746582,138.676559 
	C178.415695,133.504608 180.027405,130.189011 184.059662,127.208900 
	C178.382233,128.396057 175.899673,125.518227 174.547272,120.517563 
	C173.576920,125.982483 170.364594,127.685257 165.366196,127.656532 
	C169.974503,130.256393 170.314941,133.963089 168.883011,137.776978 
	C171.614471,137.181839 173.839661,136.697021 176.758667,136.707123 
M133.340302,191.204178 
	C134.638077,190.093933 135.935852,188.983688 137.233612,187.873444 
	C131.846481,188.109207 129.583969,185.048721 127.891769,180.636871 
	C126.859505,185.819000 123.829033,187.760391 118.736542,187.436951 
	C122.889183,190.284729 124.099693,193.715500 121.254555,199.250443 
	C124.517921,197.336334 126.312889,196.283508 128.019608,195.282455 
	C129.949738,196.360901 131.692657,197.334732 134.088287,198.673264 
	C133.596863,195.726578 133.277222,193.809967 133.340302,191.204178 
M179.177917,192.539444 
	C180.651367,190.900620 182.124817,189.261810 183.598267,187.623001 
	C177.993332,188.864624 176.207123,184.973038 174.506134,180.482529 
	C173.533936,185.820618 170.217865,187.428909 165.423950,187.611740 
	C170.008408,190.194580 170.338058,193.749680 168.426773,198.686920 
	C171.223419,197.172699 172.999893,196.210846 174.657898,195.313141 
	C176.530289,196.371780 178.186172,197.307983 180.799957,198.785797 
	C180.144333,196.265503 179.750107,194.750046 179.177917,192.539444 
M215.097809,196.462845 
	C217.893860,200.356216 218.773590,194.453049 222.183121,195.984848 
	C222.743332,196.298615 224.507767,197.286865 226.272202,198.275101 
	C224.315216,193.316635 226.466339,190.167267 230.159012,187.170105 
	C224.820267,187.998993 222.313049,185.295212 220.797852,180.624207 
	C219.758606,185.746857 216.648163,187.566681 210.548889,186.928741 
	C214.023254,190.015945 217.834641,191.649536 215.097809,196.462845 
z"
        />
        <path
          fill="#E6E7EE"
          opacity="1.000000"
          stroke="none"
          d="
M96.738091,82.459991 
	C101.836975,83.344566 103.402962,80.180710 104.804054,75.911934 
	C106.029892,81.153831 109.097198,83.304260 114.381882,82.828651 
	C110.293243,85.871170 108.487221,89.112068 110.945007,94.024971 
	C106.858994,91.677643 103.086716,89.355309 98.856544,94.680534 
	C102.297203,88.997444 97.804604,86.221031 96.738091,82.459991 
z"
        />
        <path
          fill="#E7E8EE"
          opacity="1.000000"
          stroke="none"
          d="
M49.810585,84.201340 
	C49.797924,83.430099 50.106850,82.464218 50.261398,82.488319 
	C54.952526,83.219826 57.071064,80.526283 58.493038,75.942276 
	C59.768368,81.017647 62.648205,83.432419 67.844322,83.415855 
	C66.340591,84.797417 64.836853,86.178978 63.245388,87.641144 
	C63.666512,89.602219 64.098564,91.614174 64.731209,94.560242 
	C62.156193,93.036850 60.382999,91.987816 58.575161,90.918289 
	C56.821712,91.970978 55.149315,92.974998 52.926174,94.309669 
	C53.145393,90.337799 55.010517,86.195160 49.810585,84.201340 
z"
        />
        <path
          fill="#E8E8EE"
          opacity="1.000000"
          stroke="none"
          d="
M244.641083,76.906982 
	C245.557129,81.811256 248.695404,83.159691 253.584015,82.762787 
	C249.226456,85.835869 248.023621,89.304283 249.982880,94.318741 
	C245.922668,90.529846 242.172607,90.676605 238.149033,94.342789 
	C240.231323,89.327431 239.070618,85.848175 234.497482,82.745300 
	C240.372192,84.018112 242.107101,79.964577 244.641083,76.906982 
z"
        />
        <path
          fill="#E8E9EF"
          opacity="1.000000"
          stroke="none"
          d="
M151.070282,76.837868 
	C153.143402,80.327888 155.093948,83.628891 160.699661,82.807915 
	C156.612534,85.846275 154.927338,89.269112 156.547745,93.973434 
	C154.870468,92.975090 153.193176,91.976753 151.419891,90.921272 
	C149.629288,91.979935 147.848358,93.032860 145.273544,94.555168 
	C145.902115,91.625847 146.334244,89.612038 146.761108,87.622704 
	C145.111557,86.193665 143.541138,84.833191 140.967789,82.603851 
	C146.305939,82.784500 149.893829,81.802277 151.070282,76.837868 
z"
        />
        <path
          fill="#E8E8EF"
          opacity="1.000000"
          stroke="none"
          d="
M251.488403,112.207291 
	C252.147919,112.850647 252.673859,113.980705 252.635330,114.000290 
	C248.290970,116.208496 248.777267,119.856049 249.894150,124.301491 
	C245.924057,120.337364 242.184280,120.653870 239.485382,123.550407 
	C238.408554,119.915947 237.372635,116.419525 236.227142,112.553246 
	C239.556030,112.935928 243.104187,111.469818 243.729950,105.383499 
	C246.002701,108.965042 246.182022,113.531586 251.488403,112.207291 
z"
        />
        <path
          fill="#E8E9EF"
          opacity="1.000000"
          stroke="none"
          d="
M150.257507,107.725159 
	C150.884766,107.158287 151.372055,106.934219 151.859360,106.710152 
	C153.085754,110.886635 155.335709,113.675797 161.119919,112.519142 
	C156.457367,115.663589 154.933533,119.122330 157.386612,124.184456 
	C153.265045,120.443001 149.540176,120.677330 145.020584,125.193436 
	C145.868973,121.643074 146.315140,119.775925 146.834335,117.603134 
	C145.395432,116.323456 143.831787,114.932838 142.006882,113.309883 
	C144.645370,111.105736 150.308121,113.833069 150.257507,107.725159 
z"
        />
        <path
          fill="#E8E8EF"
          opacity="1.000000"
          stroke="none"
          d="
M103.410248,108.742661 
	C103.901573,107.727852 104.237541,107.060898 105.154305,105.240944 
	C106.100792,111.014801 108.925751,113.234200 114.335320,112.687897 
	C110.237564,115.744476 108.395821,119.143677 111.014671,124.092606 
	C106.910225,121.281212 103.299263,119.855583 99.227982,124.238655 
	C100.468460,119.545174 100.674103,115.512459 95.293541,113.597641 
	C97.980270,110.918816 102.934364,114.121246 103.410248,108.742661 
z"
        />
        <path
          fill="#E7E7EE"
          opacity="1.000000"
          stroke="none"
          d="
M194.687744,92.805710 
	C193.559738,92.911781 192.739319,92.802582 191.918915,92.693375 
	C194.222504,88.476685 191.688965,85.803497 187.724701,82.868767 
	C192.625107,82.377052 196.855743,82.215134 197.463943,76.262047 
	C199.071884,81.081284 201.996536,83.407547 207.185410,82.800064 
	C203.318008,85.865761 201.058090,88.930557 204.046860,93.575974 
	C200.520386,94.402298 198.257080,88.479576 194.687744,92.805710 
z"
        />
        <path
          fill="#EAEAEF"
          opacity="1.000000"
          stroke="none"
          d="
M63.145844,142.063263 
	C64.828758,142.154694 66.115112,142.239258 68.740425,142.411804 
	C66.443817,144.476852 64.954697,145.815811 63.186775,147.405472 
	C63.633789,149.219193 64.129227,151.229416 65.095680,155.150757 
	C60.542786,150.981186 56.800823,149.904343 52.705605,154.140839 
	C54.621147,149.236542 54.039265,145.479080 47.694725,142.837036 
	C53.411781,141.935791 58.429295,142.622589 57.362206,135.081848 
	C59.850903,138.303909 61.300098,140.180161 63.145844,142.063263 
z"
        />
        <path
          fill="#E9E9EF"
          opacity="1.000000"
          stroke="none"
          d="
M104.372604,136.726532 
	C105.636452,138.056000 106.643379,139.638000 108.029999,141.816528 
	C109.408943,141.939728 111.501343,142.126663 114.968651,142.436447 
	C110.889313,145.631119 107.969223,148.748520 111.005310,154.041382 
	C106.820816,150.783813 103.071426,150.101624 99.123184,154.259979 
	C100.499901,149.570175 100.365234,145.770752 94.178032,142.934784 
	C99.537949,142.226089 103.888046,142.214417 104.372604,136.726532 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M189.266769,172.239594 
	C194.470398,173.205170 196.272415,170.114456 197.762329,165.641617 
	C198.886780,170.752747 201.651627,173.257324 207.197372,172.469131 
	C202.758423,175.453506 201.554703,179.002487 203.679199,183.863861 
	C199.683563,180.972290 196.065857,179.819321 191.891541,184.012177 
	C194.686203,178.745987 191.136002,175.790131 189.266769,172.239594 
z"
        />
        <path
          fill="#E8E9EF"
          opacity="1.000000"
          stroke="none"
          d="
M59.970615,108.434631 
	C60.269905,114.243530 65.521080,110.883469 67.986626,113.256683 
	C66.280693,114.766060 64.727509,116.140282 63.206017,117.486465 
	C63.667191,119.614594 64.100754,121.615303 64.718719,124.466957 
	C62.135777,122.936829 60.347988,121.877747 58.550503,120.812920 
	C56.773548,121.897026 55.112686,122.910309 51.992359,124.813995 
	C54.610619,119.300072 54.004002,115.539337 47.603260,112.946701 
	C53.467525,112.064430 58.397751,112.617722 57.304867,104.539345 
	C59.035805,106.909332 59.458557,107.488159 59.970615,108.434631 
z"
        />
        <path
          fill="#E9EAEF"
          opacity="1.000000"
          stroke="none"
          d="
M249.178436,149.697037 
	C249.511932,151.245346 249.744720,152.417709 250.306717,155.248093 
	C245.795380,150.243256 242.185760,150.626556 238.057251,154.216232 
	C240.089844,149.356049 239.258102,145.660034 235.494766,143.908005 
	C238.703506,141.654953 241.640167,139.592941 244.700867,137.443832 
	C245.540451,140.439178 247.820602,143.440750 253.017731,142.085373 
	C251.516144,144.843002 250.296936,147.082047 249.178436,149.697037 
z"
        />
        <path
          fill="#EAEAEF"
          opacity="1.000000"
          stroke="none"
          d="
M146.879059,153.584473 
	C146.444962,151.757767 146.306595,149.753510 146.103455,146.810913 
	C145.374023,146.159195 143.806961,144.759094 141.275253,142.497131 
	C144.284714,142.241119 146.268402,142.072372 148.599991,141.874023 
	C149.368805,140.119598 150.199814,138.223236 151.529953,135.187851 
	C152.841919,140.490158 155.098373,143.521988 160.821472,142.392105 
	C157.392166,145.590073 154.374268,148.613052 157.473938,153.651474 
	C153.660278,152.059021 150.378357,149.486404 146.879059,153.584473 
z"
        />
        <path
          fill="#E8E8EF"
          opacity="1.000000"
          stroke="none"
          d="
M194.675476,122.724304 
	C193.691650,123.253670 193.010086,123.560120 191.379776,124.293182 
	C193.569778,119.315735 192.906662,115.699944 188.332840,112.983681 
	C193.248337,112.734940 196.809235,111.393402 197.591415,105.712448 
	C198.807129,110.977425 201.829529,113.216080 207.155991,112.771820 
	C203.268677,115.769524 201.015671,118.838135 204.110382,123.550514 
	C200.539474,124.202637 198.238541,118.481911 194.675476,122.724304 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M127.337494,106.055687 
	C125.617943,107.074509 124.244331,107.955330 121.291603,109.848740 
	C123.885353,104.270470 123.758141,100.370552 118.538048,97.823288 
	C123.377312,97.488373 127.280586,96.747879 127.758614,90.921547 
	C129.673523,95.043030 131.566864,98.649178 137.386841,97.233704 
	C134.016083,100.567543 131.074066,103.708618 135.350021,109.499107 
	C131.613510,107.753609 129.648468,106.835648 127.337494,106.055687 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M221.659363,106.055023 
	C219.418686,106.811317 217.524750,107.705490 214.693634,109.042122 
	C216.285507,104.428040 216.777679,100.308846 211.321884,97.779831 
	C216.589355,97.781586 219.886658,96.211014 221.218750,91.151215 
	C221.965668,92.883385 222.712585,94.615547 223.631607,96.746826 
	C225.432312,96.901428 227.529663,97.081505 231.131073,97.390717 
	C226.614441,100.478004 224.123703,103.692917 226.256912,108.914299 
	C224.839981,108.007164 223.423050,107.100029 221.659363,106.055023 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M76.712646,104.658752 
	C75.664719,102.154945 74.492439,100.019974 73.107574,97.497849 
	C74.901390,97.237503 77.306412,97.531883 78.757561,96.493385 
	C80.155029,95.493317 80.566589,93.115570 82.009041,90.088928 
	C83.155106,95.467606 85.558937,98.508049 91.288773,97.389175 
	C87.034531,100.492516 85.502335,103.955986 88.626801,109.520172 
	C83.789314,106.811775 80.138710,103.616005 76.612427,110.050514 
	C76.413620,109.534691 76.214813,109.018867 76.016006,108.503044 
	C76.206772,107.344559 76.397537,106.186081 76.712646,104.658752 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M244.655029,166.616302 
	C245.423157,171.701279 248.920380,172.475250 254.478851,172.240128 
	C249.497330,175.572067 247.726715,178.867813 250.096344,183.715851 
	C245.971008,181.132889 242.185989,179.350388 238.111221,184.421463 
	C240.106308,179.176895 239.649857,175.372055 234.283051,172.450180 
	C240.708557,174.020294 242.061325,169.482239 244.655029,166.616302 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M106.316925,181.403625 
	C103.790939,181.959610 101.575348,182.726410 98.776665,183.695023 
	C100.534546,179.377945 100.398865,175.569046 94.482475,172.832306 
	C99.695503,171.892792 104.706505,172.473373 104.445778,165.490936 
	C106.298805,170.387192 108.601501,173.572296 114.409103,172.410294 
	C110.018982,175.493454 108.510071,178.982147 110.284775,183.837708 
	C109.065620,183.096603 107.846474,182.355515 106.316925,181.403625 
z"
        />
        <path
          fill="#EBEBF0"
          opacity="1.000000"
          stroke="none"
          d="
M203.621826,152.582321 
	C203.279343,153.203827 202.658569,153.748306 202.581345,153.679840 
	C199.162674,150.648178 195.748093,150.117706 193.221481,153.434830 
	C192.082809,149.762543 190.997452,146.262177 189.844101,142.542587 
	C193.217133,142.676636 196.794312,141.386475 197.557678,135.701782 
	C198.887756,140.814697 201.744400,143.235153 207.949905,142.014999 
	C204.448776,145.597763 200.725372,147.855621 203.621826,152.582321 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M53.126427,172.023987 
	C55.308094,170.261276 57.103611,168.499451 59.072121,166.567886 
	C59.695587,167.974152 60.522957,169.840347 61.389786,171.795547 
	C63.443539,171.981155 65.486542,172.165802 69.147095,172.496628 
	C63.667881,175.445923 62.298717,178.912613 64.499626,183.810547 
	C60.566830,181.031784 56.976120,179.768250 52.949108,183.957947 
	C54.419922,179.178833 54.113068,175.319443 49.636425,172.455444 
	C50.671043,172.311935 51.705658,172.168427 53.126427,172.023987 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M173.770569,92.041977 
	C175.189392,93.246048 176.430344,94.782944 178.100388,96.851257 
	C179.317688,96.953339 181.388840,97.127022 184.574005,97.394119 
	C182.386292,99.390625 180.893051,100.753365 179.182953,102.313995 
	C179.624649,104.185165 180.099564,106.197014 181.007080,110.041512 
	C176.549454,105.896172 172.843063,104.713997 168.904617,109.024742 
	C170.463455,104.083015 169.963684,100.416153 164.375290,97.944374 
	C168.714737,96.725494 173.378433,97.661644 173.770569,92.041977 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M146.042358,172.022858 
	C148.207733,170.219757 149.979828,168.417999 151.878204,166.487839 
	C152.907715,170.369324 154.912262,173.760559 161.013641,172.245071 
	C156.733856,175.470917 154.742416,178.826508 156.613281,183.703583 
	C154.934235,182.718994 153.255173,181.734390 151.440979,180.670547 
	C149.669907,181.702881 147.870987,182.751450 144.835892,184.520569 
	C147.422226,179.091110 146.658585,175.400055 140.635834,172.550888 
	C143.514862,172.248428 144.581970,172.136322 146.042358,172.022858 
z"
        />
        <path
          fill="#EEEEF1"
          opacity="1.000000"
          stroke="none"
          d="
M196.478882,197.646942 
	C197.225418,197.064774 197.808578,196.825439 198.391724,196.586090 
	C199.047668,201.417618 202.352676,202.652756 208.074081,201.992783 
	C203.666779,205.473175 200.540421,208.255859 203.012665,213.365753 
	C201.195801,212.404633 199.378952,211.443527 197.585861,210.494995 
	C195.857391,211.483109 194.196854,212.432388 191.675140,213.873978 
	C192.907806,209.105469 194.014862,205.034592 187.866638,203.443314 
	C191.172760,201.005096 196.429199,203.400650 196.478882,197.646942 
z"
        />
        <path
          fill="#EBECF0"
          opacity="1.000000"
          stroke="none"
          d="
M133.992081,126.928253 
	C135.200439,127.009666 135.997498,127.089516 138.008530,127.290985 
	C133.969986,130.319443 131.418304,133.358307 134.042709,138.421600 
	C129.916214,135.532944 126.147560,134.681534 122.259590,138.879333 
	C123.965790,133.970886 123.495621,130.327148 118.669426,127.641853 
	C123.769905,127.798462 126.930130,125.853706 127.559456,121.714943 
	C129.647308,123.522072 131.614044,125.224380 133.992081,126.928253 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M218.732697,136.689423 
	C217.442245,137.394745 216.477524,137.854950 216.484711,137.851532 
	C215.256332,134.205872 214.096497,130.763657 212.909042,127.239426 
	C216.907501,127.986771 219.659195,125.719124 220.829941,120.739563 
	C222.337677,125.396431 224.684967,128.298859 230.424240,127.307777 
	C226.024338,130.203827 224.597412,133.698914 226.881927,137.878464 
	C224.040909,137.357666 221.549652,136.900986 218.732697,136.689423 
z"
        />
        <path
          fill="#EEEEF1"
          opacity="1.000000"
          stroke="none"
          d="
M88.774887,159.996887 
	C87.788300,162.748764 87.143150,165.280182 86.427208,168.089371 
	C85.248192,167.455109 83.559464,166.546646 81.632332,165.509933 
	C80.081596,166.395630 78.410591,167.350021 75.270935,169.143250 
	C77.524467,163.944153 76.897186,160.335358 72.477432,157.683395 
	C77.447693,157.682602 80.650192,155.933777 81.642929,150.491394 
	C83.206490,155.232391 85.398613,158.586166 92.222191,156.345642 
	C90.334572,158.430725 89.725456,159.103577 88.774887,159.996887 
z"
        />
        <path
          fill="#EBECF0"
          opacity="1.000000"
          stroke="none"
          d="
M130.263062,166.689270 
	C127.444214,166.907623 124.950378,167.371384 122.133865,167.895172 
	C124.032784,163.933182 123.419601,160.336792 118.729095,157.705170 
	C123.487579,157.382980 127.520172,156.613937 127.883331,150.396515 
	C129.352997,155.678207 132.061295,158.010742 138.229004,156.821136 
	C134.234909,160.580063 130.789413,163.335693 133.484421,168.313828 
	C132.518982,167.854111 131.553528,167.394409 130.263062,166.689270 
z"
        />
        <path
          fill="#EBECF0"
          opacity="1.000000"
          stroke="none"
          d="
M249.797226,211.244720 
	C249.496841,212.141998 249.095001,212.646317 248.693176,213.150650 
	C245.320511,209.921265 242.016754,210.726578 238.146469,213.734222 
	C240.182205,208.924667 239.315323,205.277039 234.723785,202.512497 
	C239.779388,202.750717 242.983932,201.092148 243.626236,194.566406 
	C245.915833,199.672226 247.219452,204.106079 253.185028,201.467957 
	C252.390259,205.388733 246.580017,206.529831 249.797226,211.244720 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M108.520996,201.813721 
	C110.515167,201.968475 112.116608,202.044937 114.874588,202.176620 
	C110.923943,205.409897 107.847824,208.280869 111.034798,213.483582 
	C106.921967,210.986984 103.128098,209.264313 99.269936,213.832458 
	C100.253731,209.097366 100.941589,205.057404 95.270081,202.726318 
	C100.540497,202.628265 103.907883,201.159271 104.329872,194.799759 
	C106.045647,197.932663 107.086960,199.834045 108.520996,201.813721 
z"
        />
        <path
          fill="#ECECF0"
          opacity="1.000000"
          stroke="none"
          d="
M226.824738,167.382416 
	C226.410080,167.916321 225.778351,168.363007 225.637924,168.241577 
	C222.303726,165.358200 218.992966,164.851501 215.363449,168.854752 
	C216.087830,164.182297 217.087952,160.020889 211.292038,157.843964 
	C216.340057,157.453720 220.027878,156.358383 220.673004,150.546127 
	C222.380554,155.082382 224.492920,158.567932 230.238174,157.163620 
	C227.394058,160.312424 223.920761,162.704788 226.824738,167.382416 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M86.621841,132.251556 
	C86.923615,134.360016 87.368309,136.090103 88.229050,139.438782 
	C83.836433,135.896500 80.063828,134.322021 76.100426,138.777939 
	C77.810127,133.846832 76.816673,130.345306 72.565804,127.518074 
	C77.727974,127.864700 80.639091,125.786682 81.667091,120.741768 
	C83.375450,125.090584 85.260384,128.670990 90.938614,126.675148 
	C91.210838,127.264351 91.483063,127.853554 91.755287,128.442764 
	C90.091782,129.586227 88.428268,130.729706 86.621841,132.251556 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M151.086914,210.665024 
	C149.297882,211.617630 147.902679,212.415497 145.236542,213.940155 
	C147.410416,208.647018 146.348740,205.166046 141.988815,202.510147 
	C147.203110,202.896545 150.294174,200.873230 150.923477,195.376160 
	C154.623810,201.404327 154.623810,201.404327 160.767334,202.795105 
	C156.520920,205.319489 155.341675,208.750641 157.683212,213.944565 
	C154.838547,212.369492 153.159637,211.439880 151.086914,210.665024 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M179.376862,162.450714 
	C179.649353,164.447922 179.998047,166.013565 180.607407,168.749725 
	C178.064896,167.377304 176.450287,166.505753 174.553650,165.481949 
	C173.040329,166.360367 171.382172,167.322876 168.117172,169.218063 
	C170.660950,163.853622 169.761063,160.355682 164.222702,157.628220 
	C169.696289,156.979004 174.266159,156.993088 174.184372,150.340942 
	C176.078873,155.228867 178.191208,158.675903 183.971970,156.604370 
	C184.147079,157.202057 184.322189,157.799744 184.497299,158.397430 
	C182.815887,159.604660 181.134476,160.811905 179.376862,162.450714 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M63.417633,208.299301 
	C63.786850,210.087311 64.038841,211.456085 64.445923,213.667236 
	C61.907055,212.305038 60.210751,211.394897 58.536682,210.496689 
	C56.815525,211.453125 55.147717,212.379929 52.464737,213.870850 
	C54.342804,208.901581 53.946579,205.342697 49.202682,202.611847 
	C54.375187,202.806091 57.726948,201.063522 58.449303,195.513168 
	C60.052620,200.352600 62.176594,203.554367 68.559616,201.455292 
	C66.226738,204.305191 64.763573,206.092636 63.417633,208.299301 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M87.425026,190.928696 
	C87.108780,193.378510 87.108780,195.568130 87.108780,198.219177 
	C85.349442,197.254700 83.564651,196.276260 81.771721,195.293365 
	C80.013535,196.299316 78.323982,197.266022 75.431740,198.920853 
	C77.629097,193.784012 76.924706,190.175888 72.639526,187.403030 
	C77.505348,187.474625 80.672127,185.878571 81.228287,179.431519 
	C83.769730,184.179474 84.722801,189.253860 91.181763,186.023605 
	C91.121613,186.586746 91.061462,187.149902 91.001312,187.713043 
	C89.914635,188.698196 88.827950,189.683350 87.425026,190.928696 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M176.411758,136.459656 
	C173.839661,136.697021 171.614471,137.181839 168.883011,137.776978 
	C170.314941,133.963089 169.974503,130.256393 165.366196,127.656532 
	C170.364594,127.685257 173.576920,125.982483 174.547272,120.517563 
	C175.899673,125.518227 178.382233,128.396057 184.059662,127.208900 
	C180.027405,130.189011 178.415695,133.504608 180.746582,138.676559 
	C178.729416,137.680389 177.744049,137.193756 176.411758,136.459656 
z"
        />
        <path
          fill="#EDEDF1"
          opacity="1.000000"
          stroke="none"
          d="
M133.148956,191.548767 
	C133.277222,193.809967 133.596863,195.726578 134.088287,198.673264 
	C131.692657,197.334732 129.949738,196.360901 128.019608,195.282455 
	C126.312889,196.283508 124.517921,197.336334 121.254555,199.250443 
	C124.099693,193.715500 122.889183,190.284729 118.736542,187.436951 
	C123.829033,187.760391 126.859505,185.819000 127.891769,180.636871 
	C129.583969,185.048721 131.846481,188.109207 137.233612,187.873444 
	C135.935852,188.983688 134.638077,190.093933 133.148956,191.548767 
z"
        />
        <path
          fill="#EEEEF1"
          opacity="1.000000"
          stroke="none"
          d="
M179.266907,192.887024 
	C179.750107,194.750046 180.144333,196.265503 180.799957,198.785797 
	C178.186172,197.307983 176.530289,196.371780 174.657898,195.313141 
	C172.999893,196.210846 171.223419,197.172699 168.426773,198.686920 
	C170.338058,193.749680 170.008408,190.194580 165.423950,187.611740 
	C170.217865,187.428909 173.533936,185.820618 174.506134,180.482529 
	C176.207123,184.973038 177.993332,188.864624 183.598267,187.623001 
	C182.124817,189.261810 180.651367,190.900620 179.266907,192.887024 
z"
        />
        <path
          fill="#EDEEF1"
          opacity="1.000000"
          stroke="none"
          d="
M215.198349,196.085114 
	C217.834641,191.649536 214.023254,190.015945 210.548889,186.928741 
	C216.648163,187.566681 219.758606,185.746857 220.797852,180.624207 
	C222.313049,185.295212 224.820267,187.998993 230.159012,187.170105 
	C226.466339,190.167267 224.315216,193.316635 226.272202,198.275101 
	C224.507767,197.286865 222.743332,196.298615 222.183121,195.984848 
	C218.773590,194.453049 217.893860,200.356216 215.198349,196.085114 
z"
        />
      </svg>
    </div>
  );
};
// ==========================================
export const DatePicker = () => {
  const { t } = useTranslation("layout");

  return (
    <>
      <div className={styles.dateContainer}>
        <div className={styles.dateItem}>
          <div>
            <p>{t("date")}</p>
          </div>
          <div className={styles.dateInput}> </div>
        </div>
      </div>
    </>
  );
};
