import React from "react";
import styles from "../assets/modules/loading.module.css";
export default function AppLoading() {
  return (
    <div className={styles.loadingPage}>
      <div className={styles.loader}>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
      </div>
    </div>
  );
}
