import { changeLanguage } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ArabicFlagSVG, EnglishFlagSVG } from "./utilities";
import styles from "../../src/assets/modules/navbar.module.css";
import { useTranslation } from "react-i18next";
export default function Translation() {
  const { language } = useTranslation().i18n;
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<any>(null);
  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (dropdownRef !== null) {
        if (dropdownRef && !dropdownRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <>
      <div className={styles.langContainer}>
        <svg
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(!open)}
          xmlns="http://www.w3.org/2000/svg"
          width="34.993"
          height="37.513"
          viewBox="0 0 34.993 37.513"
        >
          <g transform="translate(-2.3 -3)">
            <path
              d="M11.134,16.5,10,20.015h2.268Z"
              transform="translate(1.032 1.809)"
              fill="#fff"
            />
            <path
              d="M34.053,3H15.908V8.67h4.536v2.268H5.7a3.341,3.341,0,0,0-3.4,3.4V26.814a3.341,3.341,0,0,0,3.4,3.4H6.836V36l7.144-5.784h7.6V22.278H34.053a3.341,3.341,0,0,0,3.4-3.4V6.4A3.341,3.341,0,0,0,34.053,3ZM14.547,25.567l-.567-1.814H10.465l-.68,1.814H7.063l3.742-10.093h2.722l3.742,10.093Zm17.237-8.959v2.268a10.239,10.239,0,0,1-4.423-1.134,12.155,12.155,0,0,1-4.536,1.134l-.113-2.268a8.633,8.633,0,0,0,2.381-.34,7.747,7.747,0,0,1-2.041-3.629h2.381a5.7,5.7,0,0,0,1.814,2.495,5.866,5.866,0,0,0,2.155-4.2H22.6V8.67H26V6.4h2.268V8.67h3.742L32.125,9.8a8.622,8.622,0,0,1-2.495,6.464A7.706,7.706,0,0,0,31.785,16.608Z"
              fill={open ? "var(--primary-color)" : "#000"}
            />
          </g>
        </svg>
        <span
          ref={dropdownRef}
          className={styles.langList}
          style={open === true ? { display: "block" } : { display: "none" }}
        >
          <ul
            style={{
              listStyle: "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "700",
            }}
          >
            <li
              onClick={() => {
                changeLanguage("en");
                setOpen(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "5px",
                  opacity: language === "ar" ? "1" : "0.3",
                }}
              >
                <div>En</div>
                <div>
                  <EnglishFlagSVG />
                </div>
              </div>
            </li>
            <li
              onClick={() => {
                changeLanguage("ar");
                setOpen(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "5px",
                  opacity: language === "en" ? "1" : "0.3",
                }}
              >
                <div>ع</div>
                <div>
                  <ArabicFlagSVG />
                </div>
              </div>
            </li>
          </ul>
        </span>
      </div>
    </>
  );
}
