import React from "react";
import styles from "../assets/modules/snackBar.module.css";

export default function SnackBar(props: any) {
  console.log(props.snackBar);
  const { snackBar } = props;
  return (
    <div className={styles.container}>
      <svg
        style={{ marginRight: "15px" }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="30"
        height="30"
        x="0"
        y="0"
        viewBox="0 0 486.463 486.463"
      >
        <g>
          <path
            d="M243.225 333.382c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4.6-14.3-10.7-25.6-24.4-25.6z"
            fill="#ffffff"
            data-original="#000000"
          ></path>
          <path
            d="M474.625 421.982c15.7-27.1 15.8-59.4.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4 16.3-74.9 43.4l-156.8 271.5c-15.6 27.3-15.5 59.8.3 86.9 15.6 26.8 43.5 42.9 74.7 42.9h312.8c31.3 0 59.4-16.3 75.2-43.6zm-34-19.6c-8.7 15-24.1 23.9-41.3 23.9h-312.8c-17 0-32.3-8.7-40.8-23.4-8.6-14.9-8.7-32.7-.1-47.7l156.8-271.4c8.5-14.9 23.7-23.7 40.9-23.7 17.1 0 32.4 8.9 40.9 23.8l156.7 271.4c8.4 14.6 8.3 32.2-.3 47.1z"
            fill="#ffffff"
            data-original="#000000"
          ></path>
          <path
            d="M237.025 157.882c-11.9 3.4-19.3 14.2-19.3 27.3.6 7.9 1.1 15.9 1.7 23.8 1.7 30.1 3.4 59.6 5.1 89.7.6 10.2 8.5 17.6 18.7 17.6s18.2-7.9 18.7-18.2c0-6.2 0-11.9.6-18.2 1.1-19.3 2.3-38.6 3.4-57.9.6-12.5 1.7-25 2.3-37.5 0-4.5-.6-8.5-2.3-12.5-5.1-11.2-17-16.9-28.9-14.1z"
            fill="#ffffff"
            data-original="#000000"
          ></path>
        </g>
      </svg>
      {snackBar.message && <>{snackBar.message}</>}
    </div>
  );
}
