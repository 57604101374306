import React, { useState } from "react";
import styles from "../assets/modules/home.module.css";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell } from "recharts";
import { StyledCard, CircleSvg } from "../components/utilities";
import DateSelector from "../components/DateSelector";

interface IRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface IRender {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}

export default function Home() {
  const selectionRange: IRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [value, setValue] = useState(selectionRange);
  const handleChange = (e: { selection: React.SetStateAction<IRange> }) => {
    setValue(e.selection);
  };

  const { t } = useTranslation("home");
  const data = [
    { name: "Group A", value: 25.5 },
    { name: "Group B", value: 65.75 },
    { name: "Group D", value: 10 },
  ];
  const COLORS = ["var(--red-list)", "var(--yellow-list)", "var(--green-list)"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: IRender) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.65;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={8}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div>
      <DateSelector handleChange={handleChange} value={value} />
      <p className={styles.generalStats}>{t("general_stats")}</p>
      <div className={styles.statsContainer}>
        <div
          className={styles.item}
          style={{ background: "var(--rose-color)" }}
        >
          <p>{t("clients_total")}</p>
          <p>150,000</p>
        </div>
        <div
          className={styles.item}
          style={{ background: "var(--kiwi-color)" }}
        >
          <p>{t("total_shipments")}</p>
          <p>5,000,531</p>
        </div>
        <div
          className={styles.item}
          style={{ background: "var(--blue-color)" }}
        >
          <p>{t("runner_performance")}</p>
          <p>75.33%</p>
        </div>
      </div>
      {/* ======================================================= */}
      <p className={styles.generalStats}>{t("financial_accounts")}</p>
      <div className={styles.financialAccounts}>
        <p>{t("total_income")}</p>
        <p style={{ fontSize: "40px" }}>$350,000</p>
        <div className={styles.totalDelivery}>
          <p>{t("total_delivery")}</p>
          <p style={{ fontSize: "24px" }}>$35,000</p>
        </div>
      </div>
      {/* ======================================================= */}

      <p className={styles.generalStats}>{t("branch_stats")}</p>
      <div className={styles.cardAnalysis}>
        <StyledCard>
          <div className={styles.cardContainer}>
            <div className={styles.cardInfo}>
              <p>{t("dokki_branch")}</p>
              <div className={styles.shipmentNum}>
                <p>{t("shipments")}</p>
                <span style={{ color: "var(--primary-color)" }}>50,000</span>
              </div>
            </div>
            <div className={styles.branchStats}>
              <div className={styles.branchStatsContainer}>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--green-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("deliverables")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 25.5
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--yellow-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("remaining")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 65.75
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--red-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("returns")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 10.00
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <PieChart width={130} height={130}>
                  <Pie
                    data={data}
                    cx={55}
                    cy={55}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </StyledCard>
        <StyledCard>
          <div className={styles.cardContainer}>
            <div className={styles.cardInfo}>
              <p>{t("dokki_branch")}</p>
              <div className={styles.shipmentNum}>
                <p>{t("shipments")}</p>
                <span style={{ color: "var(--primary-color)" }}>50,000</span>
              </div>
            </div>
            <div className={styles.branchStats}>
              <div className={styles.branchStatsContainer}>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--green-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("deliverables")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 25.5
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--yellow-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("remaining")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 65.75
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--red-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("returns")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 10.00
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.branchStatsPiechart}>
                <PieChart width={120} height={120}>
                  <Pie
                    data={data}
                    cx={55}
                    cy={55}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </StyledCard>
        <StyledCard>
          <div className={styles.cardContainer}>
            <div className={styles.cardInfo}>
              <p>{t("dokki_branch")}</p>
              <div className={styles.shipmentNum}>
                <p>{t("shipments")}</p>
                <span style={{ color: "var(--primary-color)" }}>50,000</span>
              </div>
            </div>
            <div className={styles.branchStats}>
              <div className={styles.branchStatsContainer}>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--green-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("deliverables")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 25.5
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--yellow-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("remaining")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 65.75
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--red-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("returns")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 10.00
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.branchStatsPiechart}>
                <PieChart width={120} height={120}>
                  <Pie
                    data={data}
                    cx={55}
                    cy={55}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </StyledCard>
        <StyledCard>
          <div className={styles.cardContainer}>
            <div className={styles.cardInfo}>
              <p>{t("dokki_branch")}</p>
              <div className={styles.shipmentNum}>
                <p>{t("shipments")}</p>
                <span style={{ color: "var(--primary-color)" }}>50,000</span>
              </div>
            </div>
            <div className={styles.branchStats}>
              <div className={styles.branchStatsContainer}>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--green-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("deliverables")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 25.5
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--yellow-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("remaining")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 65.75
                    </span>
                  </div>
                </div>
                <div className={styles.statsItem}>
                  <div className={styles.item1} style={{ display: "flex" }}>
                    <CircleSvg color={"var(--red-list)"} />
                  </div>
                  <div className={styles.item2}>
                    <p style={{ color: "var(--secondary-color)" }}>
                      {t("returns")}
                    </p>
                  </div>
                  <div className={styles.item3}>
                    <span style={{ color: "var(--secondary-color)" }}>
                      % 10.00
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.branchStatsPiechart}>
                <PieChart width={120} height={120}>
                  <Pie
                    data={data}
                    cx={55}
                    cy={55}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </StyledCard>
      </div>
    </div>
  );
}
