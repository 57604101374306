import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./helpers/i18n";
import { useTranslation } from "react-i18next";
import Loading from "./components/Loading";
function App() {
  const { language } = useTranslation().i18n;
  const isAr = language === "ar";
  return (
    <Suspense fallback={<Loading />}>
      <div
        style={{
          direction: isAr ? "rtl" : "ltr",
          fontFamily: isAr ? "Noto" : "Roboto",
        }}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
